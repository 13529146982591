import { useEffect, useState } from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';

import Loader from './common/Loader';
import PageTitle from './components/PageTitle';
import SignIn from './pages/Authentication/SignIn';
import Calendar from './pages/Calendar';
import ECommerce from './pages/Dashboard/ECommerce';
import FormElements from './pages/Form/FormElements';
import Clients from './pages/Clients.tsx';
import Product from './pages/Product.tsx';
import Category from './pages/Category.tsx';
import Order from './pages/Order.tsx';
import Rules from './pages/Rules.tsx';
import Settings from './pages/Settings';
import DefaultLayout from './layout/DefaultLayout';
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";
import CreateOrder from './pages/OrderCreate.tsx';
import ProductCreate from './pages/ProductCreate.tsx';
import OrderDetailsLoader from './pages/OrderDetailsLoader.tsx';
import InvoicesCreateLoader from './pages/InvoicesCreateLoader.tsx';
import InvoiceViewLoader from './pages/InvoiceViewLoader.tsx';
import Documentation from './pages/Documentation.tsx';


function App() {
  const [loading, setLoading] = useState<boolean>(true);
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  useEffect(() => {
    setTimeout(() => setLoading(false), 1000);
  }, []);

  return loading ? (
    <Loader />
  ) : (
    <DefaultLayout>
      <Routes>
        <Route
          index
          element={
            <>
              <PageTitle title="Alstom Dashboard | Page d'accueil" />
              <ECommerce />
            </>
          }
        />
        <Route
          path="/documentation"
          element={
            <>
              <PageTitle title="Documentation" />
              <Documentation />
            </>
          }
        />
        <Route
          path="/clients/:id/orders"
          element={
            <>
              <PageTitle title="Commande Clients | Liste des comandes clients" />
              <Calendar />
            </>
          }
        />
        <Route
          path="/clients"
          element={
            <>
              <PageTitle title="Clients | Retrouvez les clients de votre CSE" />
              <Clients />
            </>
          }
        />
        <Route
          path="/clients/quota"
          element={
            <>
              <PageTitle title="Quotas clients | Vérifier que le clients à bien respecter les quotas" />
              <FormElements />
            </>
          }
        />
        <Route
          path="/settings"
          element={
            <>
              <PageTitle title="Paramètre | Veuillez définir vos paramètres utilisateurs" />
              <Settings />
            </>
          }
        />
        <Route
          path="/products"
          element={
            <>
              <PageTitle title="Produits | Afficher les produits disponible depuis votre boutique CSE" />
              <Product />
            </>
          }
        />
        <Route
          path="/create-order"
          element={
            <>
              <PageTitle title="Créer un commande | Créer une commande client et/ou CSE" />
              <CreateOrder />
            </>
          }
        />
        <Route
          path="/categories"
          element={
            <>
              <PageTitle title="Catégorie | Afficher les catégories disponible dans votre boutique CSE" />
              <Category />
            </>
          }
        />
        <Route
          path="/rules"
          element={
            <>
              <PageTitle title="Règle de prix | Vérifier en un coup d'œil que les règle pré-définis sont correcte" />
              <Rules />
            </>
          }
        />
        <Route
          path="/invoices"
          element={
            <>
              <PageTitle title="Gestion des factures Alstom VPF" />
              <InvoicesCreateLoader />
            </>
          }
        />
        <Route
          path="/invoices-create"
          element={
            <>
              <PageTitle title="Création des factures Alstom VPF" />
              <InvoicesCreateLoader />
            </>
          }
        />
        <Route
          path="/invoice/:id"
          element={
            <>
              <PageTitle title="Création des factures Alstom VPF" />
              <InvoiceViewLoader />
            </>
          }
        />
        <Route
          path="/order"
          element={
            <>
              <PageTitle title="Gestion des commandes Alstom VPF" />
              <Order />
            </>
          }
        />
        <Route
          path="/order-create"
          element={
            <>
              <PageTitle title="Création des commandes Alstom VPF" />
              <CreateOrder />
            </>
          }
        />
        <Route
          path="/order-details/:id"
          element={
            <>
              <PageTitle title="Création des commandes Alstom VPF" />
              <OrderDetailsLoader />
            </>
          }
        />
        <Route
          path="/products-create"
          element={
            <>
              <PageTitle title="Création de produit Altom VPF" />
              <ProductCreate />
            </>
          }
        />
        <Route
          path="/login"
          element={
            <>
              <PageTitle title="Connexion Dashboard Alstom | Page de connexion utilisateur" />
              <SignIn />
            </>
          }
        />
      </Routes>
        <ToastContainer
            position="bottom-right"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            theme="light"
        />
    </DefaultLayout>
  );
}

export default App;