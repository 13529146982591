import React, { useEffect, useState } from 'react';
import Breadcrumb from '../components/Breadcrumbs/Breadcrumb';
import axios from 'axios';
import { toast } from 'react-toastify';
import { validateProduct } from '../validator/product/create';
import { validateProduct as editingProductValidation } from '../validator/product/edit';
import { Link } from 'react-router-dom';

export default function ProductCreate() {
  const params = new URLSearchParams(document.location.search);
  const [rules, setRules] = useState([]);
  const [categories, setCategories] = useState([]);
  const [savedCategories, setSavedCategories] = useState([]);
  const [searchCategories, setSearchCategories] = useState([]);
  const [searchCategory, setSearchCategory] = useState('');
  const [errors, setErrors] = useState<any>(null);
  const [loading, setLoading] = useState(false);
  const [editing] = useState<boolean>(() => {
    // check if url parameter editing is set to true and extract id
    return params.get('editing') === 'true' ? true : false;
  });

  const [id] = useState<string | null>(() => {
    return params.get('id') || null;
  });

  const [formData, setFormData] = useState({
    name: '',
    references: '',
    description: '',
    price: 0,
    stock: 0,
    startDate: '',
    endDate: '',
    isPromotional: false,
    ps_category_id: '',
    ps_discount_id: '',
  });

  const handleChange = (
    e: React.ChangeEvent<
      HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement
    >,
  ) => {
    const { name, value, type, checked } = e.target as HTMLInputElement;
    setFormData({
      ...formData,
      [name]: type === 'checkbox' ? checked : value,
    });
  };

  const handleSearchCategory = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchCategory(e.target.value);
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    const { error, value } = validateProduct(formData);

    if (error) {
      // Gérer les erreurs de validation
      const validationErrors: any = {};
      error.details.forEach((detail) => {
        validationErrors[detail.path[0]] = detail.message;
      });
      setErrors(validationErrors);
      return;
    } else {
      setErrors(null);
      toast.info('Création du produit en cours');
      const data = {
        ...value,
        isPromotional: value.isPromotional ? true : false,
        validity: {
          startDate: value.startDate,
          endDate: value.endDate,
        },
      };

      delete data.startDate;
      delete data.endDate;

      // Si les données sont valides, les envoyer au backend
      axios
        .post('/products', data)
        .then(() => {
          toast.success('Produit créé avec succès');
          setFormData({
            name: '',
            references: '',
            description: '',
            price: 0,
            stock: 0,
            startDate: '',
            endDate: '',
            isPromotional: false,
            ps_category_id: '',
            ps_discount_id: '',
          });
        })
        .catch(() => {
          toast.error('Erreur lors de la création du produit');
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  const handleEdit = (e: React.FormEvent) => {
    e.preventDefault();
    const { error, value } = editingProductValidation(formData);
    console.log(error);
    if (error) {
      // Gérer les erreurs de validation
      const validationErrors: any = {};
      error.details.forEach((detail) => {
        validationErrors[detail.path[0]] = detail.message;
      });
      setErrors(validationErrors);
      return;
    } else {
      setErrors(null);
      toast.info('Modification du produit en cours');
      const data = {
        ...value,
        isPromotional: value.isPromotional ? true : false,
        validity: {
          startDate: value.startDate,
          endDate: value.endDate,
        },
      };

      delete data.startDate;
      delete data.endDate;

      // Si les données sont valides, les envoyer au backend
      axios
        .put('/products/' + id, data)
        .then(() => {
          toast.success('Produit modifier avec succès');
        })
        .catch(() => {
          toast.error('Erreur lors de la modification du produit');
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  const getOnlyUniqueCategories = (categories: any) => {
    let arr = [];

    categories.map((category: any) => {
      arr.push(category);
    });

    return arr;
  };

  const fetchCategories = async () => {
    toast.loading('Récupération des catégories');
    try {
      await axios
        .get('/categories')
        .then((res) => res.data.categories)
        .then(setCategories)
        .then(() => toast.dismiss())
        .catch((err) => err);
    } catch (e) {
      toast.error('Erreur lors de la récupération des catégories');
    }
  };

  const fetchRules = async () => {
    toast.loading('Récupération des règles de prix');
    try {
      await axios
        .get('/limit-discount')
        .then((res) => res.data.rules)
        .then(setRules)
        .then(() => toast.dismiss())
        .catch((err) => err);
    } catch (e) {
      toast.error('Erreur lors de la récupération des règles de prix');
    }
  };

  useEffect(() => {
      fetchCategories();
      fetchRules();
  }, []);

  useEffect(() => {
    setSavedCategories(getOnlyUniqueCategories(categories));

    // Afficher les 25 premières catégories
    setSearchCategories(categories.slice(0, 50));
  }, [categories]);

  // permet de gérer la recherche de catégories
  useEffect(() => {
    if (searchCategory) {
      const filteredCategories = categories.filter((category: any) => {
        return category.name
          .toLowerCase()
          .includes(searchCategory.toLowerCase());
      });

      setSearchCategories(filteredCategories);
    } else {
      // si on a pas ou plus de recherche, on affiche les 25 premières catégories
      setSearchCategories(savedCategories.slice(0, 50));
    }
  }, [searchCategory]);

  useEffect(() => {
    if (errors) {
      toast.error('Merci de corriger les erreurs dans le formulaire');
      setTimeout(() => {
        setErrors(null);
      }, 5000);
    }
  }, [errors]);

  useEffect(() => {
    // fetch product data and fill formData
    if (editing === true) {
      const formatDateForInput = (date) => {
        const d = new Date(date);
        const year = d.getFullYear();
        const month = String(d.getMonth() + 1).padStart(2, '0'); // Mois commence à 0
        const day = String(d.getDate()).padStart(2, '0');
        return `${year}-${month}-${day}`;
      };

      axios
        .get('/products/' + id)
        .then((res) => res.data.product)
        .then((p) =>
          setFormData({
            ...p,
            startDate: formatDateForInput(p.validity.startDate),
            endDate: formatDateForInput(p.validity.endDate),
          }),
        );
    }
  }, [editing === true]);

  return (
    <div>
      <Breadcrumb
        pageName={!editing ? 'Créer un produit' : "Modification d'un produit"}
      />
      <div className="w-full mx-auto p-4 bg-white dark:bg-boxdark shadow-md rounded-md">
        <h2 className="text-2xl font-bold mb-4 text-center">
          Formulaire {editing ? "d'édition" : 'de création'} de produit
        </h2>
        <form className="space-y-4">
          <div>
            <h2>Caractéristique du produit</h2>
          </div>
          <div>
            <label className="block text-sm font-medium text-gray-700 dark:text-white">
              Nom du produit
              <span className="text-red-500">*</span>
            </label>
            <input
              type="text"
              name="name"
              value={formData.name}
              onChange={handleChange}
              className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-smdark:border-dark dark:text-white dark:bg-boxdark dark:border-strokedark"
              required
            />
            {errors && errors.name && (
              <p className="text-red-500 text-sm">{errors.name}</p>
            )}
          </div>
          <div>
            <label className="block text-sm font-medium text-gray-700 dark:text-white">
              Référence du produit (optionnel)
            </label>
            <input
              type="text"
              name="references"
              value={formData.references}
              onChange={handleChange}
              className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm dark:border-dark dark:text-white dark:bg-boxdark dark:border-strokedark"
            />
            {errors && errors.references && (
              <p className="text-red-500 text-sm">{errors.references}</p>
            )}
          </div>
          <div>
            <label className="block text-sm font-medium text-gray-700 dark:text-white">
              Ajouter un courte description à votre produit (optionnel)
            </label>
            <textarea
              name="description"
              value={formData.description}
              onChange={handleChange}
              className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm dark:border-dark dark:text-white dark:bg-boxdark dark:border-strokedark"
              rows={3}
            />
            {errors && errors.description && (
              <p className="text-red-500 text-sm">{errors.description}</p>
            )}
          </div>
          <div>
            <label className="block text-sm font-medium text-gray-700 dark:text-white">
              Prix TTC (€) <span className="text-red-500">*</span>
            </label>
            <input
              type="number"
              name="price"
              value={formData.price}
              onChange={handleChange}
              className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm dark:border-dark dark:text-white dark:bg-boxdark dark:border-strokedark"
              step="0.01"
              min="0"
            />
            {errors && errors.price && (
              <p className="text-red-500 text-sm">{errors.price}</p>
            )}
          </div>
          <div>
            <label className="block text-sm font-medium text-gray-700 dark:text-white">
              Quantité disponible <span className="text-red-500">*</span>
            </label>
            <input
              type="number"
              name="stock"
              value={formData.stock}
              onChange={handleChange}
              className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm dark:border-dark dark:text-white dark:bg-boxdark dark:border-strokedark"
              min="0"
            />
            {errors && errors.stock && (
              <p className="text-red-500 text-sm">{errors.stock}</p>
            )}
          </div>
          <div>
            <h2>Date de validité</h2>
          </div>
          <div>
            <label className="block text-sm font-medium text-gray-700 dark:text-white">
              Date de début <span className="text-red-500">*</span>
            </label>
            <input
              type="date"
              name="startDate"
              value={formData.startDate}
              onChange={handleChange}
              className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm dark:border-dark dark:text-white dark:bg-boxdark dark:border-strokedark"
            />
            {errors && errors.startDate && (
              <p className="text-red-500 text-sm">{errors.startDate}</p>
            )}
          </div>
          <div>
            <label className="block text-sm font-medium text-gray-700 dark:text-white">
              Date de fin <span className="text-red-500">*</span>
            </label>
            <input
              type="date"
              name="endDate"
              value={formData.endDate}
              onChange={handleChange}
              className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm dark:border-dark dark:text-white dark:bg-boxdark dark:border-strokedark"
            />
            {errors && errors.endDate && (
              <p className="text-red-500 text-sm">{errors.endDate}</p>
            )}
          </div>
          <div className="flex items-center">
            <input
              type="checkbox"
              name="isPromotional"
              checked={formData.isPromotional}
              onChange={handleChange}
              className="h-4 w-4 text-indigo-600 focus:ring-indigo-500 border-gray-300 rounded dark:border-dark dark:text-white dark:bg-boxdark dark:border-strokedark"
            />
            <label className="ml-2 block text-sm text-gray-700 font-bold dark:text-white">
              Produit promotionnel ? (optionnel)
            </label>
            {errors && errors.isPromotional && (
              <p className="text-red-500 text-sm">{errors.isPromotional}</p>
            )}
          </div>
          <div>
            <label className="block text-sm font-medium text-gray-700 dark:text-white">
              Rechercher une catégorie
            </label>
            <input
              type="text"
              name="searchCategory"
              placeholder="Rechercher une catégorie"
              value={searchCategory}
              onChange={handleSearchCategory}
              className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm dark:border-dark dark:text-white dark:bg-boxdark dark:border-strokedark"
            />
          </div>
          <div>
            {/* Search of 2068 categories */}
            <label className="block text-sm font-medium text-gray-700 dark:text-white">
              Sélectionner une catégorie <span className="text-red-500">*</span>
            </label>
            {editing === true ? (
              <small className={'text-red-500'}>
                Pour des raisons de sécurité la catégorie ne peut pas être
                modifier, cela ne seras plus le cas dans une prochaine mise à
                jour
              </small>
            ) : (
              <small>
                Pour des raisons de performance de votre navigateur une liste de
                50 résultats maximum vous seras affiché, merci de bien vouloir
                utiliser la recherche afin d'affiner les résultats
              </small>
            )}
            <select
              disabled={editing}
              name="ps_category_id"
              id="ps_category_id"
              value={String(formData.ps_category_id)}
              onChange={handleChange}
              className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm dark:border-dark dark:text-white dark:bg-boxdark dark:border-strokedark"
            >
              <option value="">Sélectionner une catégorie</option>
              {searchCategories?.map((category: any) => (
                <option key={category.id_category} value={category.id_category}>
                  {category.name}
                </option>
              ))}
            </select>
            {errors && errors.ps_category_id && (
              <p className="text-red-500 text-sm">{errors.ps_category_id}</p>
            )}
          </div>
          <div>
            <label className="block text-sm font-medium text-gray-700 dark:text-white">
              Règle de prix <span className="text-red-500">*</span>
            </label>
            <small>
              Merci de vous référer à la{' '}
              <Link
                to={'/documentation'}
                className="text-indigo-500 hover:text-underline hover:text-indigo-700"
              >
                documentation
              </Link>{' '}
              afin de comprendre le fonctionnement et l'application des règles
            </small>
            <select
              name="ps_discount_id"
              typeof="number"
              value={String(formData.ps_discount_id)}
              onChange={handleChange}
              id="ps_discount_id"
              className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm dark:border-dark dark:text-white dark:bg-boxdark dark:border-strokedark"
            >
              {rules?.map((rule: any) => (
                <option key={rule.id_discount} value={rule.id_discount}>
                  #{rule.id_discount} | {rule.group_name} | {rule.category_name}
                </option>
              ))}
            </select>
            {errors && errors.ps_discount_id && (
              <p className="text-red-500 text-sm">{errors.ps_discount_id}</p>
            )}
          </div>
          <button
            type="submit"
            onClick={(e) => {
              setLoading(true);
              if (editing) {
                handleEdit(e);
              } else {
                handleSubmit(e);
              }
            }}
            className="w-full bg-indigo-600 text-white py-2 px-4 rounded-md shadow hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500"
          >
            {editing ? 'Modifier' : 'Créer'} le produit
          </button>
        </form>
      </div>
    </div>
  );
}
