import dayjs from "dayjs";
import config from "../../config";
import { parseStatus } from "../../utils/order";
export default function CustomerForm({
  formData,
  handleChange,
  errors,
  sameAsCustomer,
  setSameAsCustomer,
  insertClient,
  idOfClient,
  setIdOfClient,
  editing,
  orders,
  m_orders
}: any): JSX.Element {
  return (
    <>
      <div>
        <h2>Information client</h2>
      </div>

      <div className="grid grid-cols-1 gap-3 items-center">
        <div className="space-y-2">
          <label className="block text-sm font-medium text-gray-700 dark:text-white">
            ID du client
            <span className="text-red-500">*</span>
          </label>
          <div className="flex space-x-3">
            <input
              type="numeric"
              name="idOfClient"
              value={idOfClient}
              placeholder="46896"
              disabled={(new URLSearchParams(document.location.search)).get('editing') === 'true'}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                setIdOfClient(e.target.value)
              }
              className="flex-1 px-4 py-2 border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm transition duration-300 dark:border-dark dark:text-white dark:bg-boxdark dark:border-strokedark disabled:bg-gray-400"
              required
            />
            <button
              onClick={insertClient}
              className="flex-1 px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-700"
            >
              Insérer le client
            </button>
          </div>
          {errors && errors.customer && errors.customer.ps_customer_id && (
            <p className="text-red-500 text-sm">
              {errors.customer.ps_customer_id}
            </p>
          )}
        </div>
      </div>
      <div className="flex gap-6 p-4 bg-gray-50 rounded-lg shadow-lg">
  <div className="w-1/2">
    <h2 className="font-semibold text-2xl text-gray-800 mb-4 border-b-2 pb-2">Historique Externes Client</h2>
    <details className="bg-white p-4 rounded-lg shadow-md">
      <summary className="cursor-pointer font-medium text-lg text-blue-600">Tableau des commandes externes clients</summary>
      <table className="w-full mt-3 border-collapse">
        <thead className="bg-gray-200 text-gray-600">
          <tr>
            <th className="p-2 border-b-2">ID Commande</th>
            <th className="p-2 border-b-2">Références</th>
            <th className="p-2 border-b-2">Date de commande</th>
            <th className="p-2 border-b-2">Mode de paiement</th>
            <th className="p-2 border-b-2">Actions</th>
          </tr>
        </thead>
        <tbody>
          {orders.map((item: any) => (
            <tr key={item.id_order} className="text-center border-b hover:bg-gray-100">
              <td className="p-2 text-sm">{item.id_order}</td>
              <td className="p-2 text-sm">{item.reference}</td>
              <td className="p-2 text-sm">{dayjs(item.date_add).format("dddd, DD MMMM YYYY")}</td>
              <td className="p-2 text-sm">{item.payment.replace('Monetico', '')}</td>
              <td className="p-2 text-sm">
                <button
                  className="bg-blue-500 hover:bg-blue-600 text-white px-3 py-1 rounded text-sm transition duration-150"
                  onClick={(e) => {
                    e.preventDefault();
                    window.open(`https://www.club-dreamservices.com/panel/index.php?controller=AdminOrders&id_order=${item.id_order}&vieworder`, '_blank');
                  }}
                >
                  <small>Voir</small>
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </details>
  </div>

  <div className="w-1/2">
    <h2 className="font-semibold text-2xl text-gray-800 mb-4 border-b-2 pb-2">Historique Internes Client</h2>
    <details className="bg-white p-4 rounded-lg shadow-md">
      <summary className="cursor-pointer font-medium text-lg text-blue-600">Tableau des commandes internes clients</summary>
      <table className="w-full mt-3 border-collapse">
        <thead className="bg-gray-200 text-gray-600">
          <tr>
            <th className="p-2 border-b-2">ID Commande</th>
            <th className="p-2 border-b-2">Date de commande</th>
            <th className="p-2 border-b-2">Mode de paiement</th>
            <th className="p-2 border-b-2">Actions</th>
          </tr>
        </thead>
        <tbody>
          {m_orders.map((item: any) => (
            <tr key={item._id} className="text-center border-b hover:bg-gray-100">
              <td className="p-2 text-sm">{item._id}</td>
              <td className="p-2 text-sm">{dayjs(item.createdAt).format("dddd, DD MMMM YYYY")}</td>
              <td className="p-2 text-sm">{parseStatus(item.paymentMethod)}</td>
              <td className="p-2 text-sm">
                <button
                  className="bg-blue-500 hover:bg-blue-600 text-white px-3 py-1 rounded text-sm transition duration-150"
                  onClick={(e) => {
                    e.preventDefault();
                    window.open(`${config.frontEndUrl}/order-details/${item._id}`, '_blank');
                  }}
                >
                  <small>Voir</small>
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </details>
  </div>
</div>

      <div>
        <h2>Information client</h2>
      </div>
      <div className="grid grid-cols-2 gap-3">
        <div>
          <label className="block text-sm font-medium text-gray-700 dark:text-white">
            Nom et prénom du client
            <span className="text-red-500">*</span>
          </label>
          <input
            type="text"
            name="customer.customer_full_name"
            value={formData.customer.customer_full_name}
            onChange={handleChange}
            className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm transition duration-300 dark:text-white dark:bg-boxdark dark:border-strokedark"
            required
          />
          {errors && errors.customer && errors.customer.customer_full_name && (
            <p className="text-red-500 text-sm">
              {errors.customer.customer_full_name}
            </p>
          )}
        </div>
        <div>
          <label className="block text-sm font-medium text-gray-700 dark:text-white">
            Email du client
            <span className="text-red-500">*</span>
          </label>
          <input
            type="email"
            name="customer.customer_email"
            value={formData.customer.customer_email}
            onChange={handleChange}
            className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm transition duration-300 dark:text-white dark:bg-boxdark dark:border-strokedark"
          />
          {errors && errors.customer && errors.customer.customer_email && (
            <p className="text-red-500 text-sm">
              {errors.customer.customer_email}
            </p>
          )}
        </div>
      </div>
      <div className="grid grid-cols-2 gap-3">
        <div>
          <label className="block text-sm font-medium text-gray-700 dark:text-white">
            Téléphone du client
            <span className="text-red-500">*</span>
          </label>
          <input
            type="phone"
            name="customer.customer_phone"
            value={formData.customer.customer_phone}
            onChange={handleChange}
            className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm transition duration-300 dark:text-white dark:bg-boxdark dark:border-strokedark"
          />
          {errors && errors.customer && errors.customer.customer_phone && (
            <p className="text-red-500 text-sm">
              {errors.customer.customer_phone}
            </p>
          )}
        </div>
      </div>
      <div>
        <label className="block text-sm font-medium text-gray-700 dark:text-white">
          Adresse du client
          <span className="text-red-500">*</span>
        </label>
        <input
          type="text"
          name="customer.customer_address"
          value={formData.customer.customer_address}
          onChange={handleChange}
          className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm transition duration-300 dark:text-white dark:bg-boxdark dark:border-strokedark"
          step="0.01"
          min="0"
        />
        {errors && errors.customer && errors.customer.customer_address && (
          <p className="text-red-500 text-sm">
            {errors.customer.customer_address}
          </p>
        )}
      </div>
      <div className="grid grid-cols-2 gap-3">
        <div>
          <label className="block text-sm font-medium text-gray-700 dark:text-white">
            Ville du client <span className="text-red-500">*</span>
          </label>
          <input
            type="text"
            name="customer.customer_city"
            value={formData.customer.customer_city}
            onChange={handleChange}
            className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm transition duration-300 dark:text-white dark:bg-boxdark dark:border-strokedark"
            min="0"
          />
          {errors && errors.customer && errors.customer.customer_city && (
            <p className="text-red-500 text-sm">
              {errors.customer.customer_city}
            </p>
          )}
        </div>
        <div>
          <label className="block text-sm font-medium text-gray-700 dark:text-white">
            Code postal <span className="text-red-500">*</span>
          </label>
          <input
            type="text"
            name="customer.customer_postalCode"
            value={formData.customer.customer_postalCode}
            onChange={handleChange}
            className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm transition duration-300 dark:text-white dark:bg-boxdark dark:border-strokedark"
          />
          {errors && errors.customer && errors.customer.customer_postalCode && (
            <p className="text-red-500 text-sm">
              {errors.customer.customer_postalCode}
            </p>
          )}
        </div>
      </div>
      <div>
        <label className="block text-sm font-medium text-gray-700 dark:text-white">
          Pays de résidence du client <span className="text-red-500">*</span>
        </label>
        <select
          name="customer.customer_country"
          typeof="text"
          value={String(formData.customer.customer_country)}
          onChange={handleChange}
          id="customer_country"
          className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm transition duration-300 dark:border-dark dark:text-white dark:bg-boxdark dark:border-strokedark"
        >
          <option value="">Sélectionnez un Pays</option>
          <option value="FR">France</option>
          <option value="BE">Belgique</option>
          <option value="DE">Allemagne</option>
        </select>
        {errors && errors.customer && errors.customer.customer_country && (
          <p className="text-red-500 text-sm">
            {errors.customer.customer_country}
          </p>
        )}
      </div>
      <div className="flex items-center">
        <input
          type="checkbox"
          name="isPromotional"
          checked={sameAsCustomer}
          onChange={(e) => setSameAsCustomer(e.target.checked as boolean)}
          className="h-4 w-4 text-indigo-600 focus:ring-indigo-500 border-gray-300 rounded transition duration-300 dark:text-white dark:bg-boxdark dark:border-strokedark"
        />
        <label className="ml-2 block text-sm text-gray-700 font-bold dark:text-white">
          L'adresse de facturation est la même que l'adresse client
        </label>
        {errors && errors.isPromotional && (
          <p className="text-red-500 text-sm">{errors.isPromotional}</p>
        )}
      </div>
      {!sameAsCustomer && (
        <>
          <div>
            <h2>Information de facturation</h2>
          </div>
          <div className="grid grid-cols-2 gap-3">
            <div>
              <label className="block text-sm font-medium text-gray-700 dark:text-white">
                Nom et prénom du client
                <span className="text-red-500">*</span>
              </label>
              <input
                type="text"
                name="invoice.invoice_full_name"
                value={formData.invoice.invoice_full_name}
                onChange={handleChange}
                className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm transition duration-300 dark:text-white dark:bg-boxdark dark:border-strokedark"
                required
              />
              {errors && errors.invoice && errors.invoice.invoice_full_name && (
                <p className="text-red-500 text-sm">
                  {errors.invoice.invoice_full_name}
                </p>
              )}
            </div>
            <div>
              <label className="block text-sm font-medium text-gray-700 dark:text-white">
                Email du client
                <span className="text-red-500">*</span>
              </label>
              <input
                type="email"
                name="invoice.invoice_email"
                value={formData.invoice.invoice_email}
                onChange={handleChange}
                className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm transition duration-300 dark:text-white dark:bg-boxdark dark:border-strokedark"
              />
              {errors && errors.invoice && errors.invoice.invoice_email && (
                <p className="text-red-500 text-sm">
                  {errors.invoice.invoice_email}
                </p>
              )}
            </div>
          </div>
          <div className="grid grid-cols-2 gap-3">
            <div>
              <label className="block text-sm font-medium text-gray-700 dark:text-white">
                Téléphone du client
                <span className="text-red-500">*</span>
              </label>
              <input
                type="phone"
                name="invoice.invoice_phone"
                value={formData.invoice.invoice_phone}
                onChange={handleChange}
                className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm transition duration-300"
              />
              {errors && errors.invoice && errors.invoice.invoice_phone && (
                <p className="text-red-500 text-sm">
                  {errors.invoice.invoice_phone}
                </p>
              )}
            </div>
          </div>
          <div>
            <label className="block text-sm font-medium text-gray-700 dark:text-white">
              Adresse du client
              <span className="text-red-500">*</span>
            </label>
            <input
              type="text"
              name="invoice.invoice_address"
              value={formData.invoice.invoice_address}
              onChange={handleChange}
              className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm transition duration-300"
              step="0.01"
              min="0"
            />
            {errors && errors.invoice && errors.invoice.invoice_address && (
              <p className="text-red-500 text-sm">
                {errors.invoice.invoice_address}
              </p>
            )}
          </div>
          <div className="grid grid-cols-2 gap-3">
            <div>
              <label className="block text-sm font-medium text-gray-700 dark:text-white">
                Ville du client <span className="text-red-500">*</span>
              </label>
              <input
                type="text"
                name="invoice.invoice_city"
                value={formData.invoice.invoice_city}
                onChange={handleChange}
                className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm transition duration-300"
                min="0"
              />
              {errors && errors.invoice && errors.invoice.invoice_city && (
                <p className="text-red-500 text-sm">
                  {errors.invoice.invoice_city}
                </p>
              )}
            </div>
            <div>
              <label className="block text-sm font-medium text-gray-700 dark:text-white">
                Code postal <span className="text-red-500">*</span>
              </label>
              <input
                type="text"
                name="invoice.invoice_postalCode"
                value={formData.invoice.invoice_postalCode}
                onChange={handleChange}
                className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm transition duration-300"
              />
              {errors &&
                errors.invoice &&
                errors.invoice.invoice_postalCode && (
                  <p className="text-red-500 text-sm">
                    {errors.invoice.invoice_postalCode}
                  </p>
                )}
            </div>
          </div>
          <div>
            <label className="block text-sm font-medium text-gray-700 dark:text-white">
              Pays de résidence du client{' '}
              <span className="text-red-500">*</span>
            </label>
            <select
              name="invoice.invoice_country"
              typeof="text"
              value={String(formData.invoice.invoice_country)}
              onChange={handleChange}
              id="customer_country"
              className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm transition duration-300 "
            >
              {editing ? (
                <option value={formData.invoice.invoice_country}>
                  {formData.invoice.invoice_country === 'FR' ? 'France' : null}
                </option>
              ) : null}
              <option value="">Sélectionnez un Pays</option>
              <option value="france">France</option>
            </select>
            {errors && errors.invoice && errors.invoice.invoice_country && (
              <p className="text-red-500 text-sm">
                {errors.invoice.invoice_country}
              </p>
            )}
          </div>
        </>
      )}
    </>
  );
}
