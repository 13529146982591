import React, { useEffect, useState } from 'react';
import Breadcrumb from '../components/Breadcrumbs/Breadcrumb';
import { DataGrid, GridColDef, GridRowSelectionModel } from "@mui/x-data-grid";
import axios from "axios";
import {toast} from 'react-toastify';
import {Paper} from "@mui/material";

const Rules = () => {
    const [rules, setRules] = useState([]);
    const [savedRules, setSavedRules] = useState([]);
    const [search, setSearch] = useState("");
    const [filterBy, setFilterBy] = useState("name");
    const [rowSelectionModel, setRowSelectionModel] =
        useState<GridRowSelectionModel>([]);
    const [selectedRules, setSelectedRules] = useState([]);
    const [selectedRulesCount, setSelectedRulesCount] = useState(0);
    
    const fetchRules = async () => {
        try {
            const response = await axios.get("/limit-discount");
            setRules(response.data.rules);
            setSavedRules(response.data.rules);
        } catch (error) {
            console.error(error);
            toast.error("Une erreur est survenue lors de la récupération des règles");
        }
    };

    useEffect(() => {
        if(rules.length === 0) {
            fetchRules();
        }
    }, []);

    const columns: GridColDef[] = [
        { field: 'group_name', headerName: 'Nom du groupe', width: 200 },
        { field: 'category_name', headerName: 'Nom de catégorie', width: 200 },
        { field: 'id_rule', headerName: 'ID Règle', width: 150 },
        { field: 'quantity', headerName: 'Quantité', width: 200 },
        { field: 'price', headerName: 'Prix', width: 150 },
        { field: 'active', headerName: 'Règle activé', width: 150 },
    ];

    useEffect(() => {
      if(search) {
        const filteredRules = savedRules.filter(rule => {
          return rule[filterBy].toLowerCase().includes(search.toLowerCase());
        });
        setRules(filteredRules);
      } else {
        setRules(savedRules);
      }
    }, [search, filterBy, rules]);

    const capitalizeFirstLetter = (string: string) =>
      string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();

    const rows = rules.map((rule => {
        return {
          id: rule.id_discount,
          id_rule: rule.id_discount,
          group_name: capitalizeFirstLetter(rule.group_name),
          category_name: `(${rule.id_category})` + capitalizeFirstLetter(rule.category_name),
          quantity: rule.from_quantity,
          price: rule.price + " €",
          active: rule.active === 1 ? "Règle active" : "Règle inactive",
        };
    }));

    return (
        <>
            <Breadcrumb pageName="Règle de votre boutique" />
           <div className="flex flex-col my-5">
                <label className="text-text text-left mb-2">Filtrer par :</label>
                  <div className="flex gap-4">
                      <select
                          className="p-1 px-3 rounded bg-card transition duration-300 hover:bg-cardHover text-text"
                          value={filterBy}
                          onChange={(e) => setFilterBy(e.target.value)}
                      >
                          <option value="category_name">Nom de catégorie</option>
                          <option value="group_name">Nom de groupe</option>
                          <option value="price">Prix</option>
                      </select>
                      <input
                          className="w-full rounded placeholder:text-textGray bg-card transition duration-300 hover:bg-cardHover text-text p-3"
                          name="search"
                          type="text"
                          placeholder={`Rechercher ici...`}
                          value={search}
                          onChange={(e) => setSearch(e.target.value)}
                      />
                  </div>
              </div>
          <Paper sx={{ height: 600, width: "100%" }}>
              <DataGrid
                  localeText={{
                      footerRowSelected: (count) => {
                          return `${count.toLocaleString()} client${
                              count > 1 ? "s" : ""
                          } sélectionné${count > 1 ? "s" : ""}`;
                      },
                      footerTotalRows: "Total de règles",
                  }}
                  rows={rows}
                  columns={columns}
                  loading={rules.length === 0}
                  pageSizeOptions={[5, 10, 25, 50, 100]}
                  checkboxSelection={true}
                  onRowSelectionModelChange={(newRowSelectionModel) => {
                      setRowSelectionModel(newRowSelectionModel);
                  }}
                  rowSelectionModel={rowSelectionModel}
                  sx={{
                      border: 0,
                      "& .MuiDataGrid-root, & .MuiDataGrid-cell, & .MuiDataGrid-columnHeaders":
                          {
                              fontFamily: "'Sora', sans-serif",
                          },
                  }}
              />
          </Paper>
        </>
    );
};

export default Rules;