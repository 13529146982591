import SearchProductCard from "./SearchProductCard";

export default function SearchProductsResults({ products, newProduct, prefillProduct }) {
    return (
        <div className='mt-3'>
            {newProduct.name.length > 0 && (
                <p>Résultat(s) pour la recherche: {newProduct.name}</p>
            )}
            {/** Si l'on souhaite afficher les produits sans avoir effectué de rechercher il suffit de supprimer: "&& newProduct.name.length > 0" */}
            {products.length > 0 && newProduct.name.length > 0 ? (
                <>
                    <div className="grid grid-cols-3 gap-3">
                        {products.map((product) => {
                            return SearchProductCard({ product, prefillProduct })
                        })}
                    </div>
                </>
            ) : newProduct.name.length > 0 ? (
                (
                    <>
                        <p className='text-red-500'>Aucun résultat pour votre recherche actuelle</p>
                    </>
                )
            ) : null}
            {/** N'affiche pas le message d'erreur au debut du chargement de la page si l'utilisateur n'a pas renseigner le champs de recherche */}
        </div>
    )
}