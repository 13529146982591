import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter as Router } from 'react-router-dom';
import App from './App';
import './css/style.css';
import './css/satoshi.css';
import 'jsvectormap/dist/css/jsvectormap.css';
import 'flatpickr/dist/flatpickr.min.css';
import axios from "axios";
import { jwtDecode } from "jwt-decode";
// @ts-ignore
import config from "./config";
import "dayjs/locale/fr";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import * as Sentry from "@sentry/react";

Sentry.init({
  dsn: "https://d943db9af39e2bbe2afdf5247f16f8e4@o4508291057254400.ingest.de.sentry.io/4508291083665488",
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration(),
  ],
  // Tracing
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: ["alstom-vpf.club-dreamservices.com", /^https:\/\/alstom-vpf-srv\.club-dreamservices\.com/],
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

dayjs.extend(relativeTime);
dayjs.locale("fr");
axios.interceptors.request.use(c => {
    const token = localStorage.getItem("token");
    if (token) {
        try {
          const user = jwtDecode(token);
          const now = Date.now().valueOf() / 1000;
          // @ts-ignore
          if (user.exp < now) {
              localStorage.removeItem("token");
              window.location.href = "/login";
          } else {
              c.headers.Authorization = `Bearer ${token}`;
          }
        } catch (e) {
            localStorage.removeItem("token");
            window.location.href = "/login";
        }
    }

    c.baseURL = config.backendUrl;

    return c;
})

ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(
  <React.StrictMode>
    <Router>
      <App />
    </Router>
  </React.StrictMode>,
);
