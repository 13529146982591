import { useAxios } from "../hooks/useAxios";
import jsPDF from 'jspdf';
import dayjs from "dayjs";
import config from "../config";
import OrderDetailsProps from "../types/order_details_props";

export const fetchPSHistory = (id: string|number) => {
    return useAxios(`/limit-user/${id}`);
};

export const fetchMongoDBHistory = (id: string|number) => {
    return useAxios(`/m/limit-user/${id}`);
};

export const fetchRules = () => {
    return useAxios(`/limit-discount`);
};

export const fetchUser = (id: string|number) => {
    return useAxios(`/users/${id}`);
};

export const fetchAllProducts = () => {
    return useAxios(`/products`);
};

export const fetchOrders = () => {
    return useAxios(`/m/orders`);
}

export const fetchOrder = (id: string|number) => {
    return useAxios(`/m/orders/${id}/populate`);
}

export const parseStatus = (status: string) => {
    switch(status) {
      case 'Pending':
        return 'En attente';
      case 'Confirmed':
        return 'Confirmée';
      case 'Shipped':  
        return 'Expédiée';
      case 'Delivered':
        return 'Livré';
      case 'Cancelled':
        return 'Annulée';
      case 'Paid':
        return 'Payée';
      case 'Refunded':
        return 'Remboursée';
      case 'Failed':
        return 'Echouée';
      case 'Credit Card':
        return 'Carte bancaire';
      case 'Cash':
        return 'Espèces';
      case 'Paypal':
        return 'Paypal';
      case 'Bank Transfer':
        return 'Virement bancaire';
      case 'Check':
        return 'Chèque';
      default:
        return 'En attente';
    }
  };

  export const generateInvoicePDF = (order: OrderDetailsProps) => {
    const doc = new jsPDF();
    const lineHeight = 10;
    const paddingLeft = 10;
  
    // Format du numéro de facture
    const invoiceNumber = `${dayjs(order.createdAt).format('YYMMDD')}.${config.shop_id || '000'}-${order.invoice_id || '1'}`;
  
    // En-tête de la facture
    doc.setFontSize(20);
    doc.setTextColor(40, 40, 40);
    doc.text(`Facture n° ${invoiceNumber}`, paddingLeft, 20);
  
    // Informations du client
    doc.setFontSize(16);
    doc.setTextColor(60, 60, 60);
    doc.text("Informations du Client", paddingLeft, 35);
    doc.setFontSize(12);
    doc.setTextColor(80, 80, 80);
    doc.text(`Nom: ${order?.customer?.customer_full_name}`, paddingLeft, 45);
    doc.text(`Email: ${order?.customer?.customer_email}`, paddingLeft, 55);
    doc.text(`Téléphone: ${order?.customer?.customer_phone}`, paddingLeft, 65);
    doc.text(`Adresse: ${order?.customer?.customer_address}, ${order?.customer?.customer_city}, ${order?.customer?.customer_postalCode}, ${order?.customer?.customer_country}`, paddingLeft, 75);
  
    // Ligne de séparation
    doc.setDrawColor(200, 200, 200);
    doc.line(paddingLeft, 85, 200, 85);
  
    // Détails de la commande
    doc.setFontSize(16);
    doc.setTextColor(60, 60, 60);
    doc.text("Détails de la Commande", paddingLeft, 95);
  
    // En-tête du tableau
    let startY = 105;
    doc.setFontSize(12);
    doc.setTextColor(40, 40, 40);
    doc.setFillColor(230, 230, 230);
    doc.rect(paddingLeft, startY - lineHeight / 2, 190, lineHeight, 'F');
    doc.text("Produit", paddingLeft, startY);
    doc.text("Prix Unitaire", paddingLeft + 50, startY);
    doc.text("Quantité", paddingLeft + 80, startY);
    doc.text("Total Réduction", paddingLeft + 100, startY);
    doc.text("Promo applicable ?", paddingLeft + 150, startY);
  
    // Lignes des produits
    startY += lineHeight;
    doc.setTextColor(80, 80, 80);
    order.productsOrdered.forEach((item) => {
      doc.text(item.product.name.substr(0, 20) + "...", paddingLeft, startY);
      doc.text(`${item.priceAtOrder.toFixed(2)} €`, paddingLeft + 50, startY);
      doc.text(`${item.quantity}`, paddingLeft + 90, startY);
      const lineTotal = item.isRemised ? (item.remisedPricePerUnit * item.quantity).toFixed(2) : (item.priceAtOrder * item.quantity).toFixed(2);
      doc.text(`${lineTotal} €`, paddingLeft + 100, startY);
      const discount = item.isRemised ? ((item.priceAtOrder - item.remisedPricePerUnit) * item.quantity).toFixed(2) : "0.00";
      doc.text(item.canApplyPromo ? "Oui" : "Non", paddingLeft + 150, startY);
      startY += lineHeight;
    });
  
    // Résumé de la commande
    startY += lineHeight;
    doc.setFontSize(16);
    doc.setTextColor(60, 60, 60);
    doc.text("Résumé", paddingLeft, startY);
  
    startY += lineHeight;
    doc.setFontSize(12);
    doc.setTextColor(80, 80, 80);
    const allProductTotalPriceWithoutRemisedPrice = order?.productsOrdered?.map((item) => item.priceAtOrder * item.quantity).reduce((a, b) => a + b, 0) || 0;
    const allProductTotalPriceWithRemisedPrice = order?.productsOrdered?.map((item) => item.canApplyPromo ? item.remisedPriceTotal : 0).reduce((a, b) => a + b, 0) || 0;
    const remisedPriceTotal = allProductTotalPriceWithoutRemisedPrice - allProductTotalPriceWithRemisedPrice;
  
    doc.text(`Total sans réduction: ${allProductTotalPriceWithoutRemisedPrice.toFixed(2)} €`, paddingLeft, startY);
    startY += lineHeight;
    doc.text(`Total avec réduction: ${allProductTotalPriceWithRemisedPrice.toFixed(2)} €`, paddingLeft, startY);
    startY += lineHeight;
    doc.text(`Total réduction: ${remisedPriceTotal.toFixed(2)} €`, paddingLeft, startY);
    startY += lineHeight;
    doc.text(`Status: ${parseStatus(order.status)}`, paddingLeft, startY);
    startY += lineHeight;
    doc.text(`Statut de paiement: ${parseStatus(order.paymentStatus)}`, paddingLeft, startY);
    startY += lineHeight;
    doc.text(`Méthode de paiement: ${parseStatus(order.paymentMethod)}`, paddingLeft, startY);
  
    // Pied de page
    doc.setTextColor(150, 150, 150);
    doc.setFontSize(10);
    doc.text(`Généré le: ${dayjs().format('DD/MM/YYYY, HH:mm')}`, paddingLeft, 280);
  
    // Sauvegarde du fichier PDF
    doc.save(`Facture_${invoiceNumber}.pdf`);
  };