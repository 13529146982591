import React, { useState, useEffect } from 'react';
import Breadcrumb from '../components/Breadcrumbs/Breadcrumb';
import { DataGrid, GridColDef, GridRowSelectionModel } from '@mui/x-data-grid';
import axios from 'axios';
import { toast } from 'react-toastify';
import { Paper } from '@mui/material';
import { Link } from 'react-router-dom';
import { parseStatus } from '../utils/order';

export default function Order() {
  const [orders, setOrders] = useState([]);
  const [savedOrders, setSavedOrders] = useState([]);
  const [filterBy, setFilterBy] = useState('name');
  const [search, setSearch] = useState('');
  const [rowSelectionModel, setRowSelectionModel] =
    useState<GridRowSelectionModel>([]);
  const [selectedOrders, setSelectedOrders] = useState([]);
  const [selectedOrdersCount, setSelectedOrdersCount] = useState(0);
  const [categories, setCategories] = useState([]);

  const deletedOrder = async (id) => {
    await axios
      .delete('/m/orders/' + id)
      .then(() => {
        toast.success('Votre produit a bien été supprimé.');
        const orderWithoutDeletedOrder = orders.filter(
          (order) => order?._id !== id,
        );
        setOrders(orderWithoutDeletedOrder);
        setSavedOrders(orderWithoutDeletedOrder);
      })
      .catch(() => {
        toast.error(
          'Une erreur est survenue lors de la suppression du produit.',
        );
      });
  };

  const fetchOrders = async () => {
    try {
      await axios
        .get('/m/orders')
        .then((response) => response.data.orders)
        .then((data) => {
          setOrders(data);
          setSavedOrders(data);
        });
    } catch (error) {
      console.error(error);
      toast.error(
        'Une erreur est survenue lors de la récupération des produits',
      );
    }
  };

  const fetchCategories = async () => {
    try {
      const response = await axios.get('/categories');
      setCategories(response.data.categories);
    } catch (e) {
      console.error(e);
      toast.error(
        'Une erreur est survenue lors de la récupération des catégories',
      );
    }
  };

  useEffect(() => {
    fetchOrders();
    fetchCategories();
  }, []);

  const columns: GridColDef[] = [
    { field: 'id', headerName: 'ID', width: 90 },
    { field: 'status', headerName: 'Statut', width: 100 },
    { field: 'paymentStatus', headerName: 'Statut Paiement', width: 100 },
    { field: 'totalAmount', headerName: 'Montant Total', width: 150 },
    { field: 'nbr_product', headerName: 'Nbr Produits', width: 100 },
    { field: 'customer_full_name', headerName: 'Nom client', width: 170 },
    { field: 'customer_full_email', headerName: 'Email client', width: 200 },
    { field: 'customer_full_phone', headerName: 'Tel client', width: 150 },
  ];

  useEffect(() => {
    if (search) {
      const filteredOrders = savedOrders.filter((product) => {
        return product[filterBy].toLowerCase().includes(search.toLowerCase());
      });
      setOrders(filteredOrders);
    } else {
      setOrders(savedOrders);
    }
  }, [search, filterBy, orders]);

  const capitalizeFirstLetter = (string: string) =>
    string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();

  const getCurrentCategoryName = (id) => {
    const category = categories?.find((c) => c.id_category === id);
    return category ? category.name : '';
  };

  const rows = orders?.map((product) => {
    return {
      id: product?._id,
      status: parseStatus(product?.status),
      paymentStatus: parseStatus(product?.paymentStatus),
      totalAmount: parseFloat(product?.totalAmount).toFixed(2) + ' €',
      nbr_product: product?.productsOrdered.length,
      customer_full_name: product?.customer.customer_full_name,
      customer_full_email: product?.customer.customer_email,
      customer_full_phone: product?.customer.customer_phone,
    };
  });

  useEffect(() => {
    if (rowSelectionModel) {
      setSelectedOrders(
        rowSelectionModel.map((selected) => {
          return selected;
        }),
      );
      setSelectedOrdersCount(rowSelectionModel.length);
    }
  }, [rowSelectionModel]);

  useEffect(() => {
    if (orders?.length === 0) {
      fetchOrders();
    }
  }, [orders]);

  return (
    <div>
      <Breadcrumb pageName="Gestion des commandes" />
      <div className="flex gap-3">
        <Link to={'/order-create'}>
          <button
            className={
              'p-2 bg-indigo-500 text-white rounded hover:bg-indigo-700 hover:shadow-lg'
            }
          >
            Créer une commande
          </button>
        </Link>
        {selectedOrdersCount === 1 ? (
          <Link to={'/order-details/' + selectedOrders[0]}>
            <button
              className={
                'p-2 bg-green-500 text-white rounded hover:bg-green-700 hover:shadow-lg'
              }
            >
              Détails de la commande
            </button>
          </Link>
        ) : null}
        {selectedOrdersCount === 1 ? (
          <Link to={'/order-create?id=' + selectedOrders[0] + '&editing=true'}>
            <button
              className={
                'p-2 bg-blue-500 text-white rounded hover:bg-blue-700 hover:shadow-lg'
              }
            >
              Modifier la commande
            </button>
          </Link>
        ) : null}
      </div>
      <div className="flex flex-col my-5">
        <label className="text-text text-left mb-2">Filtrer par :</label>
        <div className="flex gap-4">
          <select
            className="p-1 px-3 rounded bg-card transition duration-300 hover:bg-cardHover text-text border dark:border-strokedark dark:bg-boxdark"
            value={filterBy}
            onChange={(e) => setFilterBy(e.target.value)}
          >
            <option value="name">Nom</option>
            <option value="price">Prix</option>
          </select>
          <input
            className="w-full rounded placeholder:text-textGray bg-card transition duration-300 hover:bg-cardHover text-text p-3 border dark:border-strokedark dark:bg-boxdark"
            name="search"
            type="text"
            placeholder={`Rechercher ici...`}
            value={search}
            onChange={(e) => setSearch(e.target.value)}
          />
        </div>
      </div>
      <Paper style={{ height: 550, width: '100%' }}>
        <DataGrid
          localeText={{
            footerRowSelected: (count) => {
              return `${count.toLocaleString()} ${
                count > 1 ? 'commandes ' : 'commande '
              }sélectionné${count > 1 ? 's' : ''}`;
            },
            footerTotalRows: 'Total de commandes',
          }}
          rows={rows}
          columns={columns}
          loading={orders?.length === 0}
          pageSizeOptions={[5, 10, 25, 50, 100]}
          checkboxSelection={true}
          onRowSelectionModelChange={(newRowSelectionModel) => {
            setRowSelectionModel(newRowSelectionModel);
          }}
          rowSelectionModel={rowSelectionModel}
          sx={{
            border: 0,
            '& .MuiDataGrid-root, & .MuiDataGrid-cell, & .MuiDataGrid-columnHeaders':
              {
                fontFamily: "'Sora', sans-serif",
              },
          }}
        />
      </Paper>
    </div>
  );
}
