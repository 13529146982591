import { DSProduct } from "../../types"
import OrderProductCard from "./OrderProductCard"

interface OrderProductListProps {
  productsOrdered: DSProduct[],
  setProductsOrdered: any,
  client: any,
  editing: any,
}

export default function OrderProductList({ productsOrdered, setProductsOrdered, client, editing }: OrderProductListProps) {
  return (
    <>
      <div>
        <h2 className='text-xl'>Liste des produits ajouté dans la commande:</h2>
      </div>
      <div>
        {productsOrdered.length > 0 ? (<>
          <div className="grid grid-cols-3 gap-3">
            {
              productsOrdered.map((product, index) => {
                //console.log(product, index)
                return <OrderProductCard
                  product={product}
                  client={client}
                  productsOrdered={productsOrdered}
                  setProductsOrdered={setProductsOrdered}
                  editing={editing}
                  index={index}
                  key={product.id || product._id}
                />
              })
            }
          </div>
        </>) : (
          <p>Vous n'avez pas encore ajouté de produit à cette commande.</p>
        )}
      </div>

    </>
  )
}