export default function FilterAndFillProduct({
  isPrefilled,
  client,
  currentRules,
  applyPromotion,
  newProduct,
  savedProducts,
  handleNewProductChange,
  addToProductsOrderedList,
  editing
}) {
  return (
    <>
      <div>
        <div>
          <h2>Sélectionner un produit à insérer dans la commandes</h2>
        </div>
        <div>
          {!client ? (
            <small className="text-red-500">
              Veuillez insérer un client avant de pouvoir ajouter des produits à
              la commande
            </small>
          ) : null}
        </div>
        <div>
          {currentRules && !editing ? (
            <>
              <p className="text-red-500">
                Attention le produit que vous êtes en train d'ajouter, est
                soumis à des règles de promotions
              </p>
              <span
                className={applyPromotion ? 'text-green-500' : 'text-red-500'}
              >
                {applyPromotion
                  ? "une promotion seras applicable lors de l'ajout du produit à la commande"
                  : "la promotion n'est pas applicable car l'utilisateur à déjà utilisé sont quotas"}
              </span>
            </>
          ) : null}
        </div>
        <div className="grid grid-cols-3 gap-3 items-center justify-around">
          <div>
            <label className="block text-sm font-medium text-gray-700 dark:text-white">
              {isPrefilled
                ? 'Sélection du produit:'
                : 'Recherche de produits (par nom)'}{' '}
              <span className="text-red-500">*</span>
            </label>
            <input
              type="text"
              name="name"
              placeholder="Nom du produit"
              value={newProduct.name}
              disabled={client ? false : true}
              onChange={handleNewProductChange}
              className="mt-1 block w-full px-3 py-2 border disabled:bg-gray-400 border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm dark:border-dark dark:text-white dark:bg-boxdark dark:border-strokedark"
            />
          </div>
          <div>
            <label className="block text-sm font-medium text-gray-700 dark:text-white">
              Quantité <span className="text-red-500">*</span>
            </label>
            <input
              type="number"
              name="quantity"
              min={1}
              max={
                newProduct.id
                  ? savedProducts.find((p) => p._id === newProduct.id).stock
                  : 1
              }
              value={newProduct.quantity}
              onChange={handleNewProductChange}
              className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm dark:border-dark dark:text-white dark:bg-boxdark dark:border-strokedark"
            />
          </div>
          <div className="flex justify-center">
            {isPrefilled ? (
              <div>
                <p>
                  Quantité restantes:{' '}
                  {savedProducts.find((p) => p._id === newProduct.id).stock}{' '}
                </p>
              </div>
            ) : (
              <p></p>
            )}
            {isPrefilled ? (
              <button
                className="p-2 bg-blue-500 hover:bg-blue-700 text-white shadow rounded"
                onClick={addToProductsOrderedList}
              >
                Ajouter à la commande
              </button>
            ) : null}
          </div>
        </div>
      </div>
    </>
  );
}
