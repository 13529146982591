import React, { useEffect, useState } from 'react';
import Breadcrumb from '../components/Breadcrumbs/Breadcrumb';
import axios from 'axios';
import { toast } from 'react-toastify';
import { validateOrder } from '../validator/order/create';
import CustomerForm from '../components/Order/CustomerForm';
import OrderProductList from '../components/Order/OrderProductList';
import FilterAndFillProduct from '../components/Order/FilterAndFillProduct';
import SearchProductsResults from '../components/Order/SearchProductsResults';
import {
  DEFAULT_CUSTOMER_VALUE,
  DEFAULT_INVOICE_VALUE,
  DEFAULT_NEW_PRODUCT_VALUE,
} from '../constants/orders';
import OrderDetailsProps from '../types/order_details_props';
import { DSProduct } from '../types';
import { validateEditOrder } from '../validator/order/edit';
import OrderEditingStatusForm from '../components/Order/OrderEditingStatusForm';

const CreateOrder = () => {
  const params = new URLSearchParams(document.location.search);
  const [rules, setRules] = useState([]);
  const [products, setProducts] = useState<DSProduct[]>([]);
  const [savedProducts, setSavedProducts] = useState<DSProduct[]>([]);
  const [errors, setErrors] = useState<any>(null);
  const [order, setOrder] = useState<OrderDetailsProps>(null);
  const [orders, setOrders] = useState<OrderDetailsProps[]>([]);
  const [m_orders, m_setOrders] = useState<OrderDetailsProps[]>([]);
  const [loading, setLoading] = useState(false);
  const [client, setClient] = useState<any>(null);
  const [sameAsCustomer, setSameAsCustomer] = useState<boolean>(true);
  const [idOfClient, setIdOfClient] = useState<string>('');
  const [productsOrdered, setProductsOrdered] = useState([]);
  const [isPrefilled, setIsPrefilled] = useState<boolean>(false);
  const [currentRules, setCurrentRules] = useState<any | null>(null);
  const [applyPromotion, setApplyPromotion] = useState<boolean>(false);
  const [newProduct, setNewProduct] = useState(DEFAULT_NEW_PRODUCT_VALUE);
  const [
    prestaShopHistoryOfOrderForClient,
    setPrestaShopHistoryOfOrderForClient,
  ] = useState<any>([]);
  const [mongodbHistoryOfOrderForClient, setMongodbHistoryOfOrderForClient] =
    useState<any>([]);
  const [savedInvoice, setSavedInvoice] = useState<any>(null);

  const [editing] = useState<boolean>(() => {
    // check if url parameter editing is set to true and extract id
    return params.get('editing') === 'true' ? true : false;
  });

  const [id] = useState<string | null>(() => {
    return params.get('id') || null;
  });

  const [formData, setFormData] = useState({
    customer: DEFAULT_CUSTOMER_VALUE,
    invoice: DEFAULT_INVOICE_VALUE,
    productsOrdered: [],
    totalAmount: 0,
    status: 'Pending',
    paymentStatus: 'Pending',
    paymentMethod: 'Check',
  });

  const fetchOrder = async (id: string | number) => {
    await axios
      .get('/m/orders/' + id + '/populate')
      .then((response) => {
        setOrder(response.data);
        setFormData({
          ...formData,
          customer: response.data.customer,
          invoice: response.data.invoice,
          productsOrdered: response.data.productsOrdered,
          totalAmount: response.data.totalAmount,
          status: response.data.status,
          paymentStatus: response.data.paymentStatus,
          paymentMethod: response.data.paymentMethod,
        });

        setSavedInvoice(response.data.invoice);
        setProductsOrdered(response.data.productsOrdered);
        fetchClient(response.data.customer.ps_customer_id);
        fetchUserHistory(response.data.customer.ps_customer_id);
        fetchMongoDBUserHistory(response.data.customer.ps_customer_id);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  // récupère les règles de promotions
  const fetchRules = async () => {
    await axios.get('/limit-discount').then((response) => {
      setRules(response.data.rules);
    });
  };

  // récupère l'historique des commandes du client courant
  const fetchUserHistory = async (id: string | number) => {
    await axios
      .get('/limit-user/' + id)
      .then((response) => {
        setPrestaShopHistoryOfOrderForClient(response.data.results);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  // récupère l'historique de mongodb au niveau des commande de l'utilisateur.
  const fetchMongoDBUserHistory = async (id: string | number) => {
    await axios
      .get('/m/limit-user/' + id)
      .then((response) => {
        setMongodbHistoryOfOrderForClient(response.data.results);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  // permet de detecter les changement du formulaire
  const handleChange = (
    e: React.ChangeEvent<
      HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement
    >,
  ) => {
    const { name, value, type, checked } = e.target as HTMLInputElement;

    // Split the name attribute to get the path (e.g., "customer.customer_full_name" => ["customer", "customer_full_name"])
    const path = name.split('.');

    // Create a copy of formData
    let updatedFormData = { ...formData };

    // Use a reference to traverse the formData object based on the path
    let current = updatedFormData;

    // Traverse to the second-to-last key in the path
    for (let i = 0; i < path.length - 1; i++) {
      current = current[path[i]];
    }

    // Update the final key in the path
    current[path[path.length - 1]] = type === 'checkbox' ? checked : value;

    // Set the updated formData
    setFormData(updatedFormData);
  };

  // permet de récupérer les informations du client
  const fetchClient = async (id: string | number) => {
    await axios.get('/clients/address/' + id).then((response) => {
      setClient(response.data.address[0]);
      const c = response.data.address[0];

      if(c === undefined || c === null || c === '' || !c) {
        toast.error('Aucun client trouvé avec cet identifiant');
        return;
      }

      setFormData((previousState: any) => ({
        ...previousState,
        customer: {
          customer_full_name: c.firstname + ' ' + c.lastname,
          customer_email: c.email,
          customer_phone: c.phone,
          customer_address: c.cadresse1,
          customer_city: c.city,
          customer_postalCode: c.postcode,
          customer_country: c.iso_code,
          ps_customer_id: id,
        },
      }));
      toast.info('Client pré-rempli avec succès');
    });
  };

  // récupère les produits du cse
  const fetchProducts = async () => {
    await axios.get('/products').then((response) => {
      setProducts(response.data.products);
      setSavedProducts(response.data.products);
    });
  };

  // permet de gérer les changements de l'input du produit
  const handleNewProductChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setNewProduct({
      ...newProduct,
      [e.target.name]: e.target.value,
    });
  };

  // fonction qui permet de vérifier si le client peut appliquer une promotion sur un produit et si
  // une promotion est applicable pour le produit qu'on à préselectionner.
  const checkUserCanApplyPromotionPerProductPrestaShop = (product: any) => {
    // Gestion des règles prestashop
    if (rules.length === 0) {
      return false; // Pas de règles, pas de promo
    }

    const applicableRule = rules.find(
      (rule) => rule.id_category === String(product.ps_category_id),
    );

    if (!applicableRule) {
      return false; // Pas de règle applicable
    }

    setCurrentRules(applicableRule);
    setApplyPromotion(true); // Une règle de prix a été détectée

    if (prestaShopHistoryOfOrderForClient.length > 0) {
      const categoryOrder = prestaShopHistoryOfOrderForClient.find(
        (category_ordered: any) =>
          String(category_ordered.id_category) ===
          String(applicableRule.id_category),
      );

      if (categoryOrder) {
        // Vérifier si le client a déjà utilisé sa promotion pour cette catégorie de produit
        if (
          categoryOrder.total_quantity_ordered >= applicableRule.from_quantity
        ) {
          if (!editing) {
            setNewProduct({
              ...newProduct,
              maxQuantity:
                applicableRule.from_quantity -
                  categoryOrder.total_quantity_ordered <=
                  0
                  ? 0
                  : applicableRule.from_quantity -
                  categoryOrder.total_quantity_ordered,
            });
          }
          setApplyPromotion(false); // Le client ne peut pas utiliser sa promotion
          return false; // Ne pas appliquer la promo
        }

        // Si le client n'a pas encore utilisé sa promotion pour cette catégorie de produit
      }
    }

    if (mongodbHistoryOfOrderForClient.length > 0) {
      // map into productsOrdered object get and store into a let variable the category of the product with the quantity ordered
      const productsOrdered = mongodbHistoryOfOrderForClient.map(
        (order: any) => {
          return order.productsOrdered;
        },
      );

      // Objet pour stocker les quantités par catégorie
      const categoryQuantities = [];

      // Parcourir les produits commandés
      productsOrdered.forEach((order: any) => {
        order.forEach((p: any) => {
          const d = {
            ps_category_id: p.product.ps_category_id,
            ordered_quantity: p.quantity,
            rule_category_id: applicableRule.id_category,
            rule_quantity: applicableRule.from_quantity,
            customer_id: idOfClient,
            rule_id: applicableRule.id_discount,
          };

          // Ajouter la quantité à la catégorie existante ou l'initialiser
          if (
            categoryQuantities.find(
              (c) => c.ps_category_id === d.ps_category_id,
            )
          ) {
            categoryQuantities.find(
              (c) => c.ps_category_id === d.ps_category_id,
            ).ordered_quantity += d.ordered_quantity;
          } else {
            categoryQuantities.push(d);
          }
        });
      });

      setApplyPromotion(true); // Le client peut utiliser sa promotion

      // Check rules for each category
      categoryQuantities.forEach((category: any) => {
        if (String(category.ps_category_id) === category.rule_category_id) {
          if (category.ordered_quantity >= category.rule_quantity) {
            setNewProduct({
              ...newProduct,
              maxQuantity:
                category.rule_quantity - category.ordered_quantity <= 0
                  ? 0
                  : category.rule_quantity - category.ordered_quantity,
            });
            setApplyPromotion(false); // Le client ne peut pas utiliser sa promotion
            toast.info(
              'Le client a déjà utilisé sa promotion pour cette catégorie de produit, la promotion ne peut pas être appliquée',
            );
            return false; // Ne pas appliquer la promo
          }
        }
      });
    }

    // Si aucune commande n'est trouvée pour cette catégorie ou si les règles ne sont pas respectées
    return false; // Ne pas appliquer la promo
  };

  // TODO finir cette function
  const reduceMaxQuantity = (product) => {
    const currentRules = rules.find(
      (rule) => rule.id_category === String(product.ps_category_id),
    );
    if (
      currentRules &&
      String(product.ps_category_id) === currentRules.id_category
    ) {
      let calc = [];
      mongodbHistoryOfOrderForClient.forEach((order: any) => {
        order.productsOrdered.forEach((p: any) => {
          if (String(p.product.ps_category_id) === currentRules.id_category) {
            const AuthorizedQuantityFromMongo =
              currentRules.from_quantity - p.quantity;
            calc.push(AuthorizedQuantityFromMongo);
          }
        });
      });

      prestaShopHistoryOfOrderForClient.forEach((order: any) => {
        if (String(order.id_category) === currentRules.id_category) {
          const AuthorizedQuantityFromPresta =
            currentRules.from_quantity - order.total_quantity_ordered;
          calc.push(AuthorizedQuantityFromPresta);
        }
      });

      const unique = new Set(calc);

      // if one of is inferior to 0, we set the maxQuantity to from_quantity and canApplyPromotion to false
      // else we set the maxQuantity to the min of the array
      // en fonction du produit ajouter on vas vérifier si le stock est suffisant pour la quantité demandée et peut etre bloquer l'input
      /* if (unique.has(0)) {
        setNewProduct({
          ...newProduct,
          maxQuantity: currentRules.from_quantity,
          isRemised: currentRules
        });
      } else if ([...unique].some(value => value < 0)) { // check if any value is inferior to 0
        setNewProduct({
          ...newProduct,
          maxQuantity: currentRules.from_quantity,
          isRemised: false
        });
      } else {
        setNewProduct({
          ...newProduct,
          maxQuantity: Math.min(...unique),
          isRemised: currentRules ? true : false
        });
      } */
    }
  };

  // permet de préremplir le produit dans le formulaire
  const prefillProduct = (product: DSProduct) => {
    if (productsOrdered.find((p) => editing ? p.product._id === product._id : p?.id === product._id)) {
      toast.error('Ce produit est déjà présent dans la commande, veuillez modifier la quantité ou supprimer le produit');
      return;
    } else {
      checkUserCanApplyPromotionPerProductPrestaShop(product);
      //reduceMaxQuantity(product);
      setNewProduct({
        name: product.name,
        id: product._id,
        quantity: 1,
        priceAtOrder: product.price,
        remisedPricePerUnit: 0,
        remisedPriceTotal: 0,
        isRemised: false,
        rules_id: 'no-rule',
        maxQuantity: currentRules ? currentRules.from_quantity : 0,
      });
      setIsPrefilled(true);
    }
  };

  // confirmation de l'ajout du produit à la liste des produits de la commande
  const addToProductsOrderedList = async (e: React.FormEvent) => {
    e.preventDefault();
    // si le produit existe déjà dans la liste on vas allez modifier la quantité et vérifier si le stock est suffisant
    if (productsOrdered.find((p) => p.id === newProduct.id)) {
      const wantedQuantity =
        productsOrdered.find((p) => p.id === newProduct.id).quantity +
        newProduct.quantity;

      const product = savedProducts.find((p) => p._id === newProduct.id);
      checkUserCanApplyPromotionPerProductPrestaShop(product);

      if (wantedQuantity > product.stock) {
        toast.error(
          `Le stock du produit ${product.name} est insuffisant pour la quantité demandée, stock restant: ${product.stock}`,
        );
        return;
      }

      setProductsOrdered(
        productsOrdered.map((p) => {
          if (p.id === newProduct.id) {
            return {
              ...p,
              quantity: p.quantity + newProduct.quantity,
              remisedPricePerUnit: currentRules ? currentRules.price : 0,
              remisedPriceTotal: currentRules
                ? currentRules.price * newProduct.quantity
                : 0,
              isRemised: currentRules ? true : false,
              rules_id: currentRules ? currentRules.id_discount : 'no-rule',
              maxQuantity: currentRules ? currentRules.from_quantity : 0,
              canApplyPromotion: applyPromotion,
            };
          }
          return p;
        }),
      );
    } else {
      // si le produit n'existe pas dans la liste on l'ajoute avec les informations nécessaire et on vérifie si le stock est suffisant
      const wantedQuantity = newProduct.quantity;
      const product = savedProducts.find((p) => p._id === newProduct.id);
      if (wantedQuantity > product.stock) {
        toast.error(
          `Le stock du produit ${product.name} est insuffisant pour la quantité demandée, stock restant: ${product.stock}`,
        );
        return;
      }

      setProductsOrdered([
        ...productsOrdered,
        {
          ...newProduct,
          remisedPricePerUnit: currentRules ? currentRules.price : 0,
          remisedPriceTotal: currentRules
            ? currentRules.price * newProduct.quantity
            : 0,
          isRemised: currentRules ? true : false,
          rules_id: currentRules ? currentRules.id_discount : 'no-rule',
          maxQuantity: currentRules ? currentRules.from_quantity : 0,
          canApplyPromotion: applyPromotion,
        },
      ]);
    }

    // reset
    setNewProduct(DEFAULT_NEW_PRODUCT_VALUE);
    setCurrentRules(null);
    setIsPrefilled(false);
    setApplyPromotion(false);
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    const { error } = validateOrder(formData);

    if (error) {
      setErrors(error);
      return;
    } else {
      setErrors(null);
      if (productsOrdered.length === 0 || productsOrdered[0].product === '') {
        toast.error('Veuillez ajouter au moins un produit à la commande !');
        return;
      }

      const data = [];
      productsOrdered.forEach((product) => {
        data.push({
          product: product.id,
          quantity: product.quantity,
          priceAtOrder: product.priceAtOrder,
          remisedPricePerUnit: product.remisedPricePerUnit,
          remisedPriceTotal: product.remisedPriceTotal,
          isRemised: product.isRemised,
          rules_id: String(product.rules_id),
          canApplyPromo: product.canApplyPromotion,
        });
      });

      await axios
        .post('/m/orders', {
          customer: formData.customer,
          invoice: formData.invoice,
          productsOrdered: data,
          status: formData.status,
          paymentStatus: formData.paymentStatus,
          paymentMethod: formData.paymentMethod,
        })
        .then(() => {
          toast.success('Commande créée avec succès');
        })
        .catch((error) => {
          toast.error(
            error.response.data.message ||
            "Une erreur s'est produite lors de la création de la commande",
          );
        });
    }
  };

  const handleEdit = async (e: React.FormEvent) => {
    e.preventDefault();
    toast.info('Modification de la commande en cours...');

    const { error } = validateEditOrder(formData);

    if (error) {
      setErrors(error);
      return;
    } else {
      setErrors(null);
      if (productsOrdered.length === 0 || productsOrdered[0].product === '') {
        toast.error('Veuillez ajouter au moins un produit à la commande !');
        return;
      }

      const data = [];

      productsOrdered.forEach((product) => {
        data.push({
          product: product.id || product.product._id,
          quantity: product.quantity,
          priceAtOrder: product.priceAtOrder,
          remisedPricePerUnit: product.remisedPricePerUnit,
          remisedPriceTotal: product.remisedPriceTotal,
          isRemised: product.isRemised,
          rules_id: String(product.rules_id),
        });
      });

      await axios
        .put('/m/orders/' + id, {
          customer: formData.customer,
          invoice: formData.invoice,
          productsOrdered: data,
          status: formData.status,
          paymentStatus: formData.paymentStatus,
          paymentMethod: formData.paymentMethod,
        })
        .then(() => {
          toast.success('Commande modifiée avec succès');
        })
        .catch((error) => {
          toast.error(
            error.response.data.message ||
            "Une erreur s'est produite lors de la modification de la commande",
          );
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  // useEffect for the copy of the client's information to the form
  useEffect(() => {
    if (sameAsCustomer) {
      setFormData({
        ...formData,
        invoice: {
          invoice_full_name: formData.customer.customer_full_name,
          invoice_email: formData.customer.customer_email,
          invoice_phone: formData.customer.customer_phone,
          invoice_address: formData.customer.customer_address,
          invoice_city: formData.customer.customer_city,
          invoice_postalCode: formData.customer.customer_postalCode,
          invoice_country: formData.customer.customer_country,
        },
      });
    } else {
      setFormData({
        ...formData,
        invoice: DEFAULT_INVOICE_VALUE,
      });
    }
  }, [
    sameAsCustomer === true &&
    formData.customer.customer_email !== '' &&
    !editing,
  ]);

  useEffect(() => {
    fetchProducts();
    fetchRules();
  }, []);

  useEffect(() => {
    if (editing && id !== null) {
      fetchOrder(id);
    }
  }, [id !== null && editing]);

  useEffect(() => {
    if (order) {
      setProductsOrdered(order.productsOrdered);
      setIdOfClient(order.customer.ps_customer_id);
    }
  }, [order]);

  useEffect(() => {
    if (newProduct.name) {
      const filteredProduct = savedProducts.filter((p) => {
        return p.name.toLowerCase().includes(newProduct.name.toLowerCase());
      });

      setProducts(filteredProduct);
    } else {
      setProducts(savedProducts);
      // reset
      setIsPrefilled(false);
      setCurrentRules(null);
      setApplyPromotion(false);
    }
  }, [newProduct.name]);

  useEffect(() => {
    if(client) {
      (async () => {
        const response = await axios.get('/clients/' + idOfClient + '/orders/one-year').then(res => res.data.orders);
        if(response.length > 0) {
          setOrders(response);
        }
      })();
      (async () => {
        const response = await axios.get('/m/orders/' + client.email).then(res => res.data);
        if(response.length > 0) {
          m_setOrders(response);
        }
      })();
    }
  }, [client])

  const insertClient = (e: React.SyntheticEvent) => {
    e.preventDefault();
    fetchClient(idOfClient);
    fetchUserHistory(idOfClient);
    fetchMongoDBUserHistory(idOfClient);
  };

  return (
    <>
      <div>
        <Breadcrumb
          pageName={
            !editing ? 'Créer une commande' : "Modification d'une commande"
          }
        />
        <div className="w-full mx-auto p-4 bg-white shadow-md rounded-md border dark:border-strokedark dark:bg-boxdark ">
          <h2 className="text-2xl font-bold mb-4 text-center">
            Formulaire {editing ? "d'édition" : 'de création'} de commande
          </h2>
          <form className="space-y-4">
            <CustomerForm
              formData={formData}
              handleChange={handleChange}
              errors={errors}
              sameAsCustomer={sameAsCustomer}
              setSameAsCustomer={setSameAsCustomer}
              insertClient={insertClient}
              idOfClient={idOfClient}
              setIdOfClient={setIdOfClient}
              orders={orders}
              m_orders={m_orders}
            />

            {editing && order ? (
              <OrderEditingStatusForm
                formData={formData}
                handleChange={handleChange}
              />
            ) : null}

            <OrderProductList
              productsOrdered={productsOrdered}
              setProductsOrdered={setProductsOrdered}
              client={client}
              editing={editing}
            />

            <FilterAndFillProduct
              isPrefilled={isPrefilled}
              client={client}
              currentRules={currentRules}
              applyPromotion={applyPromotion}
              handleNewProductChange={handleNewProductChange}
              addToProductsOrderedList={addToProductsOrderedList}
              savedProducts={savedProducts}
              newProduct={newProduct}
              editing={editing}
            />

            <SearchProductsResults
              products={products}
              prefillProduct={prefillProduct}
              newProduct={newProduct}
            />
            <button
              type="submit"
              disabled={loading}
              onClick={(e) => {
                setLoading(true);
                if (editing) {
                  handleEdit(e);
                } else {
                  handleSubmit(e);
                }
              }}
              className="w-full bg-indigo-600 text-white py-2 px-4 rounded-md shadow hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 disabled:opacity-50"
            >
              {editing ? 'Modifier' : 'Créer'} la commande
            </button>
          </form>
        </div>
      </div>
      {/* MODAL UPDATE QUANTITY THEN IN EDITING MODE */}
    </>
  );
};

export default CreateOrder;