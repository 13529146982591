import Joi from 'joi';
import { tlds } from '@hapi/tlds';

interface Customer {
    customer_full_name: string;
    customer_email: string;
    customer_phone?: string;
    customer_address: string;
    customer_city: string;
    customer_postalCode: string;
    customer_country: string;
    ps_customer_id: number|string;
}

interface Invoice {
    invoice_full_name: string;
    invoice_email: string;
    invoice_phone?: string;
    invoice_address: string;
    invoice_city: string;
    invoice_postalCode: string;
    invoice_country: string;
}

interface ProductOrdered {
    product: string;
    quantity: number;
    priceAtOrder: number;
    remisedPricePerUnit?: number;
    remisedPriceTotal?: number;
    isRemised?: boolean;
    rules_id?: string;
}

interface CreateOrder {
    customer: Customer;
    invoice: Invoice;
    productsOrdered: ProductOrdered[];
    totalAmount?: number;
    status?: string;
    paymentStatus?: string;
    createdAt?: string;
    updatedAt?: string;
}

const formatJoiErrors = (errorDetails: Joi.ValidationErrorItem[]) => {
    const errors: Record<string, any> = {};
    errorDetails.forEach((error) => {
      const path = error.path.join('.');
      const value = error.message;
      const keys = path.split('.');

      keys.reduce((acc, key, index) => {
        if (index === keys.length - 1) {
          acc[key] = value;
          return;
        }
        acc[key] = acc[key] || {};
        return acc[key];
      }, errors);
    });
    return errors;
  };

export const validateOrder = (formData: CreateOrder) => {
    const schema = Joi.object({
        customer: Joi.object({
            customer_full_name: Joi.string().required().messages({
                'string.empty': 'Le nom complet du client est obligatoire.',
            }),
            customer_email: Joi.string().email({ tlds: { allow: tlds }}).required().messages({
                'string.email': 'L\'email doit être valide.',
                'any.required': 'L\'email du client est obligatoire.',
                'string.empty': 'L\'email du client est obligatoire.',
            }),
            customer_phone: Joi.string().required().messages({
                'string.empty': 'Le téléphone de facturation est obligatoire.',
            }),
            customer_address: Joi.string().required().messages({
                'string.empty': 'L\'adresse du client est obligatoire.',
            }),
            customer_city: Joi.string().required().messages({
                'string.empty': 'La ville du client est obligatoire.',
            }),
            customer_postalCode: Joi.string().required().messages({
                'string.empty': 'Le code postal du client est obligatoire.',
            }),
            customer_country: Joi.string().required().messages({
                'string.empty': 'Le pays du client est obligatoire.',
            }),
            ps_customer_id: Joi.number().integer().min(0).required().messages({
                'number.base': 'L\'ID du client doit être un nombre.',
                'number.integer': 'L\'ID du client doit être un entier.',
                'number.min': 'L\'ID du client doit être supérieur ou égal à 0.',
                'any.required': 'L\'ID du client est obligatoire.',
                'string.empty': 'L\'ID du client est obligatoire.',
            }),
        }).required(),
        invoice: Joi.object({
            invoice_full_name: Joi.string().required().messages({
                'string.empty': 'Le nom complet de la facturation est obligatoire.',
            }),
            invoice_email: Joi.string().email({ tlds: { allow: tlds }}).required().messages({
                'string.email': 'L\'email doit être valide.',
                'any.required': 'L\'email de facturation est obligatoire.',
            }),
            invoice_phone: Joi.string().required().messages({
                'string.empty': 'Le téléphone de facturation est obligatoire.',
            }),
            invoice_address: Joi.string().required().messages({
                'string.empty': 'L\'adresse de facturation est obligatoire.',
            }),
            invoice_city: Joi.string().required().messages({
                'string.empty': 'La ville de facturation est obligatoire.',
            }),
            invoice_postalCode: Joi.string().required().messages({
                'string.empty': 'Le code postal de facturation est obligatoire.',
            }),
            invoice_country: Joi.string().required().messages({
                'string.empty': 'Le pays de facturation est obligatoire.',
            }),
        }).required(),
        productsOrdered: Joi.array().items(Joi.object({
            product: Joi.string().required().messages({
                'string.empty': 'Le produit est obligatoire.',
            }),
            quantity: Joi.number().integer().min(1).required().messages({
                'number.base': 'La quantité doit être un nombre.',
                'number.integer': 'La quantité doit être un entier.',
                'number.min': 'La quantité doit être supérieure ou égale à 1.',
                'any.required': 'La quantité est obligatoire.',
            }),
            priceAtOrder: Joi.number().required().messages({
                'number.base': 'Le prix doit être un nombre.',
                'any.required': 'Le prix est obligatoire.',
            }),
            remisedPricePerUnit: Joi.number().optional(),
            remisedPriceTotal: Joi.number().optional(),
            isRemised: Joi.boolean().optional(),
            rules_id: Joi.string().optional(),
            canApplyPromotion: Joi.boolean().optional(),
            canApplyPromo: Joi.boolean().optional(),
        })).required(),
        totalAmount: Joi.number().optional().messages({
            'number.base': 'Le montant total doit être un nombre.',
            'any.required': 'Le montant total est obligatoire.',
        }),
        status: Joi.string().valid('Pending', 'Confirmed', 'Shipped', 'Delivered', 'Cancelled').optional(),
        paymentStatus: Joi.string().valid('Pending', 'Paid', 'Refunded', 'Failed').optional(),
        paymentMethod: Joi.string().valid('Credit Card', 'Paypal', 'Bank Transfer', 'Cash', 'Check').optional(),
        invoice_id: Joi.number().optional(),
    });

    const { error, value } = schema.validate(formData, { abortEarly: false });

    if (error) {
        return {
            error: formatJoiErrors(error.details),
            value: null,
        };
    }

    return {
        error: null,
        value: value,
    };
};
