

export default function OrderEditingStatusForm({ formData, handleChange }): JSX.Element {

    return (
        <>
            <div className="mt-4">
                <label htmlFor="status" className="block text-sm font-medium text-gray-700">Statut de la commande</label>
                <select
                    id="status"
                    name="status"
                    value={formData.status}
                    onChange={handleChange}
                    className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
                >
                    <option value="Pending">En attente</option>
                    <option value="Confirmed">En cours de préparation</option>
                    <option value="Shipped">Envoyée</option>
                    <option value="Delivered">Livrée</option>
                    <option value="Cancelled">Annulée</option>
                </select>
            </div>
            <div className="mt-4">
                <label htmlFor="status" className="block text-sm font-medium text-gray-700">Statut de paiement</label>
                <select
                    id="status"
                    name="paymentStatus"
                    value={formData.paymentStatus}
                    onChange={handleChange}
                    className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
                >
                    <option value="Pending">En attente</option>
                    <option value="Paid">Payée</option>
                    <option value="Refunded">Remboursée</option>
                    <option value="Failed">Erreur de paiement</option>
                </select>
            </div>
            <div className="mt-4">
                <label htmlFor="status" className="block text-sm font-medium text-gray-700">Méthode de paiement</label>
                <select
                    id="paymentMethod"
                    name="paymentMethod"
                    value={formData.paymentMethod}
                    onChange={handleChange}
                    className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
                >
                    <option value="Credit Card">Carte Bancaire</option>
                    <option value="Check">Chèque</option>
                    <option value="Paypal">Paypal</option>
                    <option value="Bank Transfert">Virement Bancaire</option>
                    <option value="Cash">Espèce</option>
                </select>
            </div>
        </>
    )
}