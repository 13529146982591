import Joi from 'joi';

interface CreateProduct {
    name: string;
    references: string;
    description: string;
    price: number;
    stock: number;
    startDate: string;
    endDate: string;
    isPromotional: boolean;
    ps_category_id: string;
    ps_discount_id: string;
}

export const validateProduct = (formData: CreateProduct) => {
    const schema = Joi.object({
        name: Joi.string().required().messages({
            'string.empty': 'Le nom du produit est obligatoire.',
        }),
        references: Joi.string().optional().empty('').messages({
            'string.empty': 'La référence du produit est obligatoire.',
        }),
        description: Joi.string().optional().empty('').messages({
            'string.empty': 'La description du produit est obligatoire.',
        }),
        price: Joi.number().required().greater(0).messages({
            'number.base': 'Le prix doit être un nombre.',
            'any.required': 'Le prix est obligatoire.',
            'number.greater': 'Le prix doit être supérieur à 0.',
        }),
        stock: Joi.number().required().greater(0).messages({
            'number.base': 'Le stock doit être un nombre.',
            'any.required': 'Le stock est obligatoire.',
            'number.greater': 'Le stock doit être supérieur à 0.',
        }),
        startDate: Joi.date().required().messages({
            'date.base': 'La date de début doit être une date valide.',
            'any.required': 'La date de début est obligatoire.',
        }),
        endDate: Joi.date().required().messages({
            'date.base': 'La date de fin doit être une date valide.',
            'any.required': 'La date de fin est obligatoire.',
        }),
        isPromotional: Joi.boolean().optional(),
        ps_category_id: Joi.string().required().messages({
            'number.base': 'L\'ID de la catégorie doit être un nombre.',
            'any.required': 'L\'ID de la catégorie est obligatoire.',
        }),
        ps_discount_id: Joi.string().required().messages({
            'number.base': 'L\'ID de la réduction doit être un nombre.',
        }),
    });

    return schema.validate(formData, { abortEarly: false });
};