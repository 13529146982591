import React, { useEffect, useState } from 'react';
import Breadcrumb from '../components/Breadcrumbs/Breadcrumb';
import { DataGrid, GridColDef, GridRowSelectionModel } from "@mui/x-data-grid";
import axios from "axios";
import {toast} from 'react-toastify';
import {Paper} from "@mui/material";
import dayjs from 'dayjs';
import {Link, useLocation} from "react-router-dom";

const Product = () => {
    const [products, setProducts] = useState([]);
    const [savedProducts, setSavedProducts] = useState([]);
    const [search, setSearch] = useState("");
    const [filterBy, setFilterBy] = useState("name");
    const [rowSelectionModel, setRowSelectionModel] =
        useState<GridRowSelectionModel>([]);
    const [selectedProducts, setSelectedProducts] = useState([]);
    const [selectedProductsCount, setSelectedProductsCount] = useState(0);
    const [categories, setCategories] = useState([]);

    const deleteProduct = async id => {
      await axios.delete('/products/' + id)
        .then(() => {
          toast.success("Votre produit a bien été supprimé.");
          const productWithoutDeletedProduct = products.filter(product => product?._id !== id);
          setProducts(productWithoutDeletedProduct);
          setSavedProducts(productWithoutDeletedProduct);
        })
        .catch(() => {
          toast.error("Une erreur est survenue lors de la suppression du produit.");
        });
    };

    const fetchProducts = async () => {
        try {
            const response = await axios.get("/products")
            .then(response => response.data.products)
            .then(data => {
              setProducts(data);
              setSavedProducts(data);
            })

        } catch (error) {
            console.error(error);
            toast.error("Une erreur est survenue lors de la récupération des produits");
        }
    };

    const fetchCategories = async () => {
      try {
        const response = await axios.get("/categories");
        setCategories(response.data.categories);
      } catch (e) {
        console.error(e);
        toast.error("Une erreur est survenue lors de la récupération des catégories");
      }
    }

    useEffect(() => {
        fetchProducts();
        fetchCategories();
    }, []);

    const columns: GridColDef[] = [
        { field: 'id', headerName: 'ID', width: 90 },
        { field: 'references', headerName: 'Référence', width: 400 },
        { field: 'name', headerName: 'Nom', width: 200 },
        { field: 'category', headerName: 'Catégorie', width: 200, type: 'custom' },
        { field: 'price', headerName: 'Prix', width: 200 },
        { field: 'stock', headerName: 'Stock', width: 150 },
        { field: 'promotion', headerName: 'Promo ?', width: 150 },
        { field: 'created_at', headerName: 'Date de création', width: 200 },
    ];

    useEffect(() => {
      if(search) {
        const filteredProducts = savedProducts.filter(product => {
          return product[filterBy].toLowerCase().includes(search.toLowerCase());
        });
        setProducts(filteredProducts);
      } else {
        setProducts(savedProducts);
      }
    }, [search, filterBy, products]);


    const capitalizeFirstLetter = (string: string) =>
      string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();

    const getCurrentCategoryName = id => {
      const category = categories?.find(c => c.id_category === id);
      return category ? category.name : '';
    }

    const rows = products?.map((product => {
        return {
          id: product?._id,
          name: capitalizeFirstLetter(product?.name),
          references: product?.references,
          category: getCurrentCategoryName(product?.ps_category_id) || 'Chargement',
          price: parseFloat(product?.price).toFixed(2) + " €",
          stock: product?.stock,
          created_at: dayjs(product?.createdAt).format("dddd, MMMM D YYYY, h:mm:ss a"),
          promotion: product?.isPromotional ? "En promo" : "Pas en promo"
        };
    }));


    useEffect(() => {
      if (rowSelectionModel) {
        setSelectedProducts(rowSelectionModel.map((selected) => {
          return selected;
        }));
        setSelectedProductsCount(rowSelectionModel.length);
      }
  }, [rowSelectionModel]);

  useEffect(() => {
    if(products?.length === 0) {
      fetchProducts();
    }
  }, [products])

    return (
        <>
            <Breadcrumb pageName="Produits CSE" />
            <div className='flex gap-3'>
                <Link to={'/products-create'}>
                    <button className={'p-2 bg-indigo-500 text-white rounded hover:bg-indigo-700 hover:shadow-lg'} >
                        Créer un produit
                    </button>
                </Link>
                {selectedProducts.length === 1 && (
              <>
                <button className={'p-2 bg-blue-500 text-white rounded hover:bg-blue-700 hover:shadow-lg'} onClick={() => {
                  window.location.href = ("/products-create?id=" + selectedProducts[0] + "&editing=true")
                }} >
                  Modifier le produit
                </button>
                <button className={'p-2 bg-red-500 text-white rounded hover:bg-red-700 hover:shadow-lg'} onClick={async () => {
                  if(window.confirm("Êtes vous sûr de vouloir supprimer le produit sélectionné ?"))
                  {
                      await deleteProduct(selectedProducts[0]);
                  } else {
                    toast.info("Le produit n'a pas été supprimé.");
                  }
                }} >
                  Supprimer le produit
                </button>
              </>
            )}
            </div>
            <div className="flex flex-col my-5">
                <label className="text-text text-left mb-2">Filtrer par :</label>
                  <div className="flex gap-4">
                      <select
                          className="p-1 px-3 rounded bg-card transition duration-300 hover:bg-cardHover text-text"
                          value={filterBy}
                          onChange={(e) => setFilterBy(e.target.value)}
                      >
                          <option value="name">Nom</option>
                          <option value="attribute_price">Prix</option>
                          <option value="category_name">Catégorie</option>
                          <option value="product_references">Référence</option>
                      </select>
                      <input
                          className="w-full rounded placeholder:text-textGray bg-card transition duration-300 hover:bg-cardHover text-text p-3"
                          name="search"
                          type="text"
                          placeholder={`Rechercher ici...`}
                          value={search}
                          onChange={(e) => setSearch(e.target.value)}
                      />
                  </div>
              </div>
            <Paper style={{ height: 400, width: '100%' }}>
            <DataGrid
                  localeText={{
                      footerRowSelected: (count) => {
                          return `${count.toLocaleString()} ${count > 1 ? 'produits ' : 'produit '}sélectionné${count > 1 ? "s" : ""}`;
                      },
                      footerTotalRows: "Total de produits",
                  }}
                  rows={rows}
                  columns={columns}
                  loading={products?.length === 0}
                  pageSizeOptions={[5, 10, 25, 50, 100]}
                  checkboxSelection={true}
                  onRowSelectionModelChange={(newRowSelectionModel) => {
                      setRowSelectionModel(newRowSelectionModel);
                  }}
                  rowSelectionModel={rowSelectionModel}
                  sx={{
                      border: 0,
                      "& .MuiDataGrid-root, & .MuiDataGrid-cell, & .MuiDataGrid-columnHeaders":
                          {
                              fontFamily: "'Sora', sans-serif",
                          },
                  }}
              />
            </Paper>
        </>
    );

};

export default Product;