import React, { useEffect, useState } from 'react';
import Breadcrumb from '../components/Breadcrumbs/Breadcrumb';
import { DataGrid, GridColDef, GridRowSelectionModel } from '@mui/x-data-grid';
import axios from 'axios';
import { toast } from 'react-toastify';
import { Paper } from '@mui/material';
import ModalConfirm from '../components/ModalConfirm';
import dayjs from 'dayjs';
const Clients = () => {
  const [clients, setClients] = useState([]);
  const [savedClients, setSavedClients] = useState([]);
  const [search, setSearch] = useState('');
  const [filterBy, setFilterBy] = useState('firstname');
  const [rowSelectionModel, setRowSelectionModel] =
    useState<GridRowSelectionModel>([]);
  const [selectedClient, setSelectedClient] = useState([]);
  const [selectedClientCount, setSelectedClientCount] = useState(0);
  const [modalOrder, setShowModalOrder] = useState(false);
  const [orders, setOrders] = useState([]);
  const [orderDetails, setOrderDetails] = useState({
    order: null,
    products: [],
  });
  const fetchClients = async () => {
    try {
      const response = await axios.get('/clients');
      setClients(response.data.clients);
      setSavedClients(response.data.clients);
    } catch (error) {
      console.error(error);
      toast.error(
        'Une erreur est survenue lors de la récupération des clients',
      );
    }
  };

  const fetchClientOrders = async (id) => {
    try {
      const response = await axios.get(`/clients/${id}/orders`);
      setOrders(response.data.orders);
    } catch (error) {
      console.error(error);
      toast.error(
        'Une erreur est survenue lors de la récupération des commandes du client',
      );
    }
  };

  const getOrderDetails = async (id: number | string) => {
    const orderResponse = await axios.get('/order/' + id).then((response) => {
      return response.data.order;
    });

    const productOrdered = await axios
      .get('/order/' + id + '/products')
      .then((response) => {
        return response.data.products;
      });

    setOrderDetails({
      order: orderResponse[0],
      products: productOrdered,
    });
  };

  const columns: GridColDef[] = [
    { field: 'id', headerName: 'ID', width: 150 },
    { field: 'firstname', headerName: 'Prénom', width: 150 },
    { field: 'lastname', headerName: 'Nom', width: 150 },
    { field: 'email', headerName: 'Email', type: 'string', width: 300 },
    { field: 'ville', headerName: 'Ville', type: 'string', width: 150 },
    { field: 'genre', headerName: 'Genre', type: 'custom', width: 100 },
    { field: 'matricule', headerName: 'Matricule', type: 'custom', width: 100 },
    { field: 'societe', headerName: 'Société', type: 'custom', width: 100 },
    {
      field: 'quotas',
      headerName: 'Quotas Utilisé',
      type: 'custom',
      width: 150,
    },
  ];

  const capitalizeFirstLetter = (string: string) =>
    string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();

  const rows = clients.map((client) => {
    return {
      id: client.id_customer,
      email: client.email,
      firstname: capitalizeFirstLetter(client.firstname),
      lastname: capitalizeFirstLetter(client.lastname),
      genre: client.id_gender === 1 ? 'Homme' : 'Femme',
      ville: client.ville ? capitalizeFirstLetter(client.ville) : '',
      matricule: client.matricule,
      societe: client.societe,
      quotas: 'Quotas utilisé',
    };
  });

  useEffect(() => {
      fetchClients();
  }, []);

  useEffect(() => {
    if (search) {
      setClients(
        savedClients.filter((client) => {
          if (filterBy === 'fullName') {
            const fullName =
              `${client.firstname} ${client.lastname}`.toLowerCase();
            return fullName.includes(search.toLowerCase());
          }
          const field = client[filterBy]?.toLowerCase() || '';
          return field.includes(search.toLowerCase());
        }),
      );
    } else {
      setClients(savedClients);
    }
  }, [search, filterBy, savedClients]);

  useEffect(() => {
    if (rowSelectionModel) {
      setSelectedClient(rowSelectionModel.map((row) => row));
      setSelectedClientCount(rowSelectionModel.length);
    }
  }, [rowSelectionModel]);

  const showOrder = async (e) => {
    e.preventDefault();
    const id = selectedClient[0];
    await fetchClientOrders(id);
    setShowModalOrder(true);
  };

  const cancelShowOrder = () => {
    setShowModalOrder(false);
  };

  const modalContent = () => {
    return (
      <div className={'bg-white'}>
        <h1 className={'text-xl font-bold'}>Commandes du client</h1>
        <div>
          {orders.map((order) => {
            return (
              <div key={order.id_order}>
                <h2 className={'text-lg font-bold'}>
                  Commande n°{order.id_order}
                </h2>
                <p className={'text-sm font-bold'}>
                  Référence: {order.reference}
                </p>
                <p className={'text-sm'}>
                  Date de commande:{' '}
                  {dayjs(order.date_add).format('dddd D MMMM YYYY')}
                </p>
                <p className={'text-sm'}>Total: {order.total_paid} €</p>
                <p className={'text-sm'}>Statut: {order.current_state}</p>
                <button
                  onClick={() => getOrderDetails(order.id_order)}
                  className={
                    'bg-opacity-50 bg-gray-200 p-2 rounded shadow hover:bg-gray-500 text-black'
                  }
                >
                  Détails de la commande n° {order.id_order}
                </button>
                {orderDetails.order &&
                orderDetails.order.id_order === order.id_order ? (
                  <div>
                    <h3 className={'text-lg font-bold'}>
                      Détails de la commande
                    </h3>
                    <p className={'text-sm'}>
                      Total: {orderDetails.order.total_paid_tax_incl} €
                    </p>
                    <p className={'text-sm'}>
                      N° de facture: {orderDetails.order.id_order_invoice}
                    </p>
                    <p className={'text-sm'}>
                      N° de livraison: {orderDetails.order.delivery_number}
                    </p>
                    <h4 className={'text-md font-bold'}>Produits commandés</h4>
                    {orderDetails.products.map((product) => {
                      return (
                        <div key={product.id_order_detail}>
                          <p className={'text-sm font-bold'}>
                            {product.product_name}
                          </p>
                          <p className={'text-sm'}>
                            Quantité: {product.product_quantity}
                          </p>
                          <p className={'text-sm'}>
                            Prix unitaire: {product.unit_price_tax_incl} €
                          </p>
                          <p className={'text-sm'}>
                            Prix total: {product.total_price_tax_incl} €
                          </p>
                        </div>
                      );
                    })}
                  </div>
                ) : null}
                <hr className={'my-2'} />
              </div>
            );
          })}
        </div>
      </div>
    );
  };

  const orderContent = orders.length > 0 ? modalContent() : null;

  // @ts-ignore
  return (
    <>
      <Breadcrumb pageName="Clients" />
      <div>
        {selectedClientCount === 1 ? (
          <div>
            <button
              className={'bg-blue-500 p-2 text-white rounded hover:bg-blue-700'}
              onClick={showOrder}
            >
              Voir les commandes du client
            </button>
          </div>
        ) : (
          <p>
            Veuillez sélectionner un client afin d'afficher les actions possible
            sur celui-ci
          </p>
        )}
      </div>
      <div>
        <div className="flex flex-col my-5">
          <label className="text-text text-left mb-2">Filtrer par :</label>
          <div className="flex gap-4">
            <select
              className="p-1 px-3 rounded bg-card transition duration-300 hover:bg-cardHover text-text"
              value={filterBy}
              onChange={(e) => setFilterBy(e.target.value)}
            >
              <option value="fullName">Nom</option>
              <option value="email">Email</option>
              <option value="ville">Ville</option>
            </select>
            <input
              className="w-full rounded placeholder:text-textGray bg-card transition duration-300 hover:bg-cardHover text-text p-3"
              name="search"
              type="text"
              placeholder={`Rechercher ici...`}
              value={search}
              onChange={(e) => setSearch(e.target.value)}
            />
          </div>
        </div>
        <Paper sx={{ height: 600, width: '100%' }}>
          <DataGrid
            localeText={{
              footerRowSelected: (count) => {
                return `${count.toLocaleString()} client${
                  count > 1 ? 's' : ''
                } sélectionné${count > 1 ? 's' : ''}`;
              },
              footerTotalRows: 'Total de clients',
            }}
            rows={rows}
            columns={columns}
            loading={clients.length === 0}
            pageSizeOptions={[5, 10, 25, 50, 100]}
            checkboxSelection={true}
            onRowSelectionModelChange={(newRowSelectionModel) => {
              setRowSelectionModel(newRowSelectionModel);
            }}
            rowSelectionModel={rowSelectionModel}
            sx={{
              border: 0,
              '& .MuiDataGrid-root, & .MuiDataGrid-cell, & .MuiDataGrid-columnHeaders':
                {
                  fontFamily: "'Sora', sans-serif",
                },
            }}
          />
        </Paper>
        {/*// @ts-ignore*/}
        <ModalConfirm
          isOpen={modalOrder}
          onClose={cancelShowOrder}
          onConfirm={() => {}}
          title={'Commande client Presta Shop'}
          cancelText={'Fermer'}
          content={orderContent}
        />
      </div>
    </>
  );
};

export default Clients;
