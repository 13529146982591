import { HashLoader } from "react-spinners";

interface LoaderProps {
    message: string;
}

export default function Loader({ message }: LoaderProps): JSX.Element {
    return (
        <div>
            <div className="flex justify-center mt-36 items-center w-full min-w-full min-h-full h-full">
                <HashLoader />
            </div>
            <p className="text-center mt-3 text-lg font-bold">{message}</p>
        </div>
    )
}