import {useEffect, useState} from 'react';
import Breadcrumb from '../components/Breadcrumbs/Breadcrumb';
import Paper from '@mui/material/Paper';
import { DataGrid, GridColDef, GridRowSelectionModel } from '@mui/x-data-grid';
import { Link } from 'react-router-dom';
import { parseStatus } from '../utils/order';

export default function InvoicesCreate(props) {
    const [filterBy, setFilterBy] = useState("_id");
    const [search, setSearch] = useState("");
    const [orders, setOrders] = useState([]);
    const [savedOrders, setSavedOrders] = useState([]);
    const [rowSelectionModel, setRowSelectionModel] =
    useState<GridRowSelectionModel>([]);
  const [selectedOrders, setSelectedOrders] = useState([]);
  const [selectedOrdersCount, setSelectedOrdersCount] = useState(0);

  const columns: GridColDef[] = [
    { field: 'id', headerName: 'ID', width: 90 },
    { field: 'status', headerName: 'Statut', width: 100 },
    { field: 'paymentStatus', headerName: 'Statut Paiement', width: 100 },
    { field: 'totalAmount', headerName: 'Montant Total', width: 150 },
    { field: 'nbr_product', headerName: 'Nbr Produits', width: 100 },
    { field: 'customer_full_name', headerName: 'Nom client', width: 170 },
    { field: 'customer_full_email', headerName: 'Email client', width: 200 },
    { field: 'customer_full_phone', headerName: 'Tel client', width: 150 },
  ];
const rows = orders?.map((product) => {
    return {
      id: product?._id,
      status: parseStatus(product?.status),
      paymentStatus: parseStatus(product?.paymentStatus),
      totalAmount: parseFloat(product?.totalAmount).toFixed(2) + ' €',
      nbr_product: product?.productsOrdered.length,
      customer_full_name: product?.customer.customer_full_name,
      customer_full_email: product?.customer.customer_email,
      customer_full_phone: product?.customer.customer_phone,
    };
  });

  useEffect(() => {
    if (rowSelectionModel) {
      setSelectedOrders(
        rowSelectionModel.map((selected) => {
          return selected;
        }),
      );
      setSelectedOrdersCount(rowSelectionModel.length);
    }
  }, [rowSelectionModel]);

  useEffect(() => {
    if(search) {
        const filteredOrders = savedOrders?.filter((order) => {
            return order[filterBy].toLowerCase().includes(search.toLowerCase());
        })
        setOrders(filteredOrders);
    }
  }, [search, filterBy, props.orders]);

  useEffect(() => {
    if(props?.orders) {
        setSavedOrders(props?.orders);
        setOrders(props?.orders);
    }
  }, [props?.orders?.length > 0]);

    return (
        <div>
            <Breadcrumb pageName="Gestion Des Factures" />
            <div className="flex flex-col my-5">
                <div className='flex gap-3'>
                    {selectedOrdersCount === 1 ? (
                        <Link to={'/invoice/' + selectedOrders[0]}>
                            <button
                            className={
                                'p-2 bg-blue-500 text-white rounded hover:bg-blue-700 hover:shadow-lg'
                            }
                            >
                            Voir la facture
                            </button>
                        </Link>
                    ) : null}
                </div>
                <p className='text-red-500'>Seul les commandes marquée comme terminée et payée peuvent générer une factures</p>
                <label className="text-text text-left mb-2">Rechercher l'id de la commande afin de générer la facture :</label>
                  <div className="flex gap-4">
                      <select
                          className="p-1 px-3 rounded bg-card transition duration-300 hover:bg-cardHover text-text"
                          value={filterBy}
                          onChange={(e) => setFilterBy(e.target.value)}
                      >
                          <option value="_id">ID de la commande</option>
                          <option value="totalAmount">Prix</option>
                          <option value="email">Email Client</option>
                          <option value="tel">Téléphone Client</option>
                          <option value="name">Nom ou prénom</option>
                      </select>
                      <input
                          className="w-full rounded placeholder:text-textGray bg-card transition duration-300 hover:bg-cardHover text-text p-3"
                          name="search"
                          type="text"
                          placeholder={`Rechercher ici...`}
                          value={search}
                          onChange={(e) => setSearch(e.target.value)}
                      />
                  </div>
              </div>
              <Paper style={{ height: 550, width: '100%' }}>
            <DataGrid
            localeText={{
                footerRowSelected: (count) => {
                return `${count.toLocaleString()} ${
                    count > 1 ? 'commandes ' : 'commande '
                }sélectionné${count > 1 ? 's' : ''}`;
                },
                footerTotalRows: 'Total de commandes',
            }}
            rows={rows}
            columns={columns}
            loading={orders?.length === 0}
            pageSizeOptions={[5, 10, 25, 50, 100]}
            checkboxSelection={true}
            onRowSelectionModelChange={(newRowSelectionModel) => {
                setRowSelectionModel(newRowSelectionModel);
            }}
            rowSelectionModel={rowSelectionModel}
            sx={{
                border: 0,
                '& .MuiDataGrid-root, & .MuiDataGrid-cell, & .MuiDataGrid-columnHeaders':
                {
                    fontFamily: "'Sora', sans-serif",
                },
            }}
            />
        </Paper>
        </div>
    )
}