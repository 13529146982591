import React from 'react';
import Breadcrumb from '../components/Breadcrumbs/Breadcrumb';


import { useState } from 'react';

function Navbar() {
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  return (
    <nav className="bg-gray-800 rounded">
      <div className="max-w-7xl mx-auto px-2 sm:px-6 lg:px-8">
        <div className="relative flex items-center justify-between h-16">
          <div className="absolute inset-y-0 left-0 flex items-center sm:hidden">
            <button
              onClick={toggleMenu}
              className="inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-white hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
              aria-controls="mobile-menu"
              aria-expanded="false"
            >
              <span className="sr-only">Open main menu</span>
              {isOpen ? (
                <svg
                  className="block h-6 w-6"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  aria-hidden="true"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M6 18L18 6M6 6l12 12"
                  />
                </svg>
              ) : (
                <svg
                  className="block h-6 w-6"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  aria-hidden="true"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M4 6h16M4 12h16M4 18h16"
                  />
                </svg>
              )}
            </button>
          </div>
          <div className="flex-1 flex items-center justify-center sm:items-stretch sm:justify-start">
            <div className="hidden sm:block sm:ml-6">
              <div className="flex space-x-4">
                <a
                  href="#intro"
                  className="text-gray-300 hover:bg-gray-700 hover:text-white px-3 py-2 rounded-md text-sm font-medium"
                >
                  Introduction
                </a>
                <a
                  href="#clients"
                  className="text-gray-300 hover:bg-gray-700 hover:text-white px-3 py-2 rounded-md text-sm font-medium"
                >
                  Clients
                </a>
                <a
                  href="#categories"
                  className="text-gray-300 hover:bg-gray-700 hover:text-white px-3 py-2 rounded-md text-sm font-medium"
                >
                  Catégories
                </a>
                <a
                  href="#products"
                  className="text-gray-300 hover:bg-gray-700 hover:text-white px-3 py-2 rounded-md text-sm font-medium"
                >
                  Produits
                </a>
                <a
                  href="#orders"
                  className="text-gray-300 hover:bg-gray-700 hover:text-white px-3 py-2 rounded-md text-sm font-medium"
                >
                  Commandes
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Menu mobile */}
      {isOpen && (
        <div className="sm:hidden" id="mobile-menu">
          <div className="px-2 pt-2 pb-3 space-y-1">
            <a
              href="#"
              className="text-gray-300 hover:bg-gray-700 hover:text-white block px-3 py-2 rounded-md text-base font-medium"
            >
              Accueil
            </a>
            <a
              href="#"
              className="text-gray-300 hover:bg-gray-700 hover:text-white block px-3 py-2 rounded-md text-base font-medium"
            >
              Services
            </a>
            <a
              href="#"
              className="text-gray-300 hover:bg-gray-700 hover:text-white block px-3 py-2 rounded-md text-base font-medium"
            >
              Contact
            </a>
          </div>
        </div>
      )}
    </nav>
  );
}

const OrderDetails: React.FC = () => {
  return (
    <div className="container mx-auto px-4 py-6">
      <Breadcrumb pageName="Documentation" />
      <Navbar />

      <main className="pt-20 max-w-7xl mx-auto px-4">
        {/* Introduction */}
        <section id="intro" className="py-8">
          <h2 className="text-2xl font-semibold mb-4">Introduction</h2>
          <p className="leading-relaxed">
            Bienvenue dans la documentation. Ici, vous trouverez des informations essentielles pour vous aider à démarrer avec notre application. Explorez les sections pour comprendre les fonctionnalités et les détails de configuration, à l'avenir d'autres fonctionnalités seront ajoutées au fur et à mesure des prochaines mise à jour.
          </p>
        </section>

        {/* Section 1 */}
        <section id="clients" className="py-8 border-t">
          <h2 className="text-2xl font-semibold mb-4">Gestion des clients</h2>
          <p className="leading-relaxed mb-4">
            La gestion des clients ce feras uniquement, via le panel d'administration externes. Vous pourrez ajouter, modifier, supprimer des clients.
          </p>
          <img src="/path/to/your/image1.jpg" alt="Illustration étape 1" className="w-full h-auto my-4" />
        </section>

        {/* Section 2 */}
        <section id="categories" className="py-8 border-t">
          <h2 className="text-2xl font-semibold mb-4">Gestion des catégories</h2>
          <p className="leading-relaxed mb-4">
            Comme pour les clients les catégories sont propre au panel externes de ce fait, vous pourrez ajouter, modifier, supprimer des catégories, uniquement à partir de celui-ci.
          </p>
          <img src="/path/to/your/image2.jpg" alt="Illustration des fonctionnalités" className="w-full h-auto my-4" />
        </section>

        {/* Section 3 */}
        <section id="products" className="py-8 border-t">
          <h2 className="text-2xl font-semibold mb-4">Gestion des produits</h2>
          <p className="leading-relaxed">
            La gestion des produits ce fait uniquement via le panel d'administration interne. Vous pourrez ajouter, modifier, supprimer des produits.
          </p>

          <p className="leading-relaxed">
            Voici un tutoriel pour la gestion des produits :
          </p>
          <img src="/path/to/your/image3.jpg" alt="Illustration API" className="w-full h-auto my-4" />
        </section>

        {/* Section 4 */}
        <section id="orders" className="py-8 border-t">
          <h2 className="text-2xl font-semibold mb-4">Gestion des commandes</h2>
          <p className="leading-relaxed">
            La gestion des commandes ce fait uniquement via le panel d'administration interne. Vous pourrez ajouter, modifier, supprimer des commandes.
          </p>
          <p className="leading-relaxed">
            Voici un tutoriel pour la gestion des commandes :
          </p>
          <img src="/path/to/your/image3.jpg" alt="Illustration API" className="w-full h-auto my-4" />
        </section>

        {/* Section 5 */}
        <section id="invoices" className="py-8 border-t">
          <h2 className="text-2xl font-semibold mb-4">Gestion des factures</h2>
          <p className="leading-relaxed">
            La gestion des factures ce fait uniquement via le panel d'administration interne. Vous pourrez ajouter, modifier, supprimer des factures.
          </p>
          <p className="leading-relaxed">
            Voici un tutoriel pour la gestion des factures :
          </p>
          <img src="/path/to/your/image3.jpg" alt="Illustration API" className="w-full h-auto my-4" />
        </section>

        {/* Section 6 */}
        <section id="rules" className="py-8 border-t">
          <h2 className="text-2xl font-semibold mb-4">Gestion des règle de limitation</h2>
          <p className="leading-relaxed">
            Les règles de limitation ce font uniquement sur le panel externes. Vous pourrez ajouter, modifier, supprimer des règles de limitation.
          </p>
          <img src="/path/to/your/image3.jpg" alt="Illustration API" className="w-full h-auto my-4" />
        </section>
      </main>

      {/* Footer */}
      <footer className="bg-gray-200 text-center py-4 mt-8">
        <p className="text-gray-600 text-sm">© 2023 Votre Entreprise - Tous droits réservés</p>
      </footer>
    </div>

  );
};

export default OrderDetails;
