import Joi from 'joi';
import { tlds } from '@hapi/tlds';

interface Customer {
    customer_full_name?: string;
    customer_email?: string;
    customer_phone?: string;
    customer_address?: string;
    customer_city?: string;
    customer_postalCode?: string;
    customer_country?: string;
    ps_customer_id?: number|string;
}

interface Invoice {
    invoice_full_name?: string;
    invoice_email?: string;
    invoice_phone?: string;
    invoice_address?: string;
    invoice_city?: string;
    invoice_postalCode?: string;
    invoice_country?: string;
}

interface ProductOrdered {
    product?: string;
    quantity?: number;
    priceAtOrder?: number;
    remisedPricePerUnit?: number;
    remisedPriceTotal?: number;
    isRemised?: boolean;
    rules_id?: string;
}

interface EditOrder {
    customer?: Customer;
    invoice?: Invoice;
    productsOrdered?: ProductOrdered[];
    totalAmount?: number;
    status?: string;
    paymentStatus?: string;
    createdAt?: string;
    updatedAt?: string;
}

export const validateEditOrder = (formData: EditOrder) => {
    const schema = Joi.object({
        customer: Joi.object({
            customer_full_name: Joi.string().optional().messages({
                'string.empty': 'Le nom complet du client ne peut pas être vide.',
            }),
            customer_email: Joi.string().email({ tlds: { allow: tlds }}).optional().messages({
                'string.email': 'L\'email doit être valide.',
            }),
            customer_phone: Joi.string().optional(),
            customer_address: Joi.string().optional().messages({
                'string.empty': 'L\'adresse du client ne peut pas être vide.',
            }),
            customer_city: Joi.string().optional().messages({
                'string.empty': 'La ville du client ne peut pas être vide.',
            }),
            customer_postalCode: Joi.string().optional().messages({
                'string.empty': 'Le code postal du client ne peut pas être vide.',
            }),
            customer_country: Joi.string().optional().messages({
                'string.empty': 'Le pays du client ne peut pas être vide.',
            }),
            ps_customer_id: Joi.number().integer().min(0).optional().messages({
                'number.base': 'L\'ID du client doit être un nombre.',
                'number.integer': 'L\'ID du client doit être un entier.',
                'number.min': 'L\'ID du client doit être supérieur ou égal à 0.',
            }),
        }).optional(),

        invoice: Joi.object({
            invoice_full_name: Joi.string().optional().messages({
                'string.empty': 'Le nom complet de la facture ne peut pas être vide.',
            }),
            invoice_email: Joi.string().email({ tlds: { allow: tlds }}).optional().messages({
                'string.email': 'L\'email doit être valide.',
            }),
            invoice_phone: Joi.string().optional(),
            invoice_address: Joi.string().optional().messages({
                'string.empty': 'L\'adresse de la facture ne peut pas être vide.',
            }),
            invoice_city: Joi.string().optional().messages({
                'string.empty': 'La ville de la facture ne peut pas être vide.',
            }),
            invoice_postalCode: Joi.string().optional().messages({
                'string.empty': 'Le code postal de la facture ne peut pas être vide.',
            }),
            invoice_country: Joi.string().optional().messages({
                'string.empty': 'Le pays de la facture ne peut pas être vide.',
            }),
        }).optional(),

        productsOrdered: Joi.array().items(
            Joi.object({
                _id: Joi.string().optional().empty(''),
                product: Joi.any().optional().messages({
                    'string.empty': 'Le nom du produit ne peut pas être vide.',
                }),
                quantity: Joi.number().integer().min(1).optional().messages({
                    'number.base': 'La quantité doit être un nombre.',
                    'number.integer': 'La quantité doit être un entier.',
                    'number.min': 'La quantité doit être au moins 1.',
                }),
                priceAtOrder: Joi.number().min(0).optional().messages({
                    'number.base': 'Le prix doit être un nombre.',
                    'number.min': 'Le prix doit être supérieur ou égal à 0.',
                }),
                remisedPricePerUnit: Joi.number().min(0).optional().messages({
                    'number.base': 'Le prix remisé par unité doit être un nombre.',
                    'number.min': 'Le prix remisé par unité doit être supérieur ou égal à 0.',
                }),
                remisedPriceTotal: Joi.number().min(0).optional().messages({
                    'number.base': 'Le prix total remisé doit être un nombre.',
                    'number.min': 'Le prix total remisé doit être supérieur ou égal à 0.',
                }),
                isRemised: Joi.boolean().optional(),
                rules_id: Joi.string().optional().empty(''),
                canApplyPromotion: Joi.boolean().optional(),
                canApplyPromo: Joi.boolean().optional(),
            })
        ).optional().messages({
            'array.base': 'Les produits commandés doivent être une liste.',
        }),

        totalAmount: Joi.number().min(0).optional().messages({
            'number.base': 'Le montant total doit être un nombre.',
            'number.min': 'Le montant total doit être supérieur ou égal à 0.',
        }),

        status: Joi.string().valid('Pending', 'Confirmed', 'Shipped', 'Delivered', 'Cancelled').optional(),
        paymentStatus: Joi.string().valid('Pending', 'Paid', 'Refunded', 'Failed').optional(),
        paymentMethod: Joi.string().valid('Credit Card', 'Paypal', 'Bank Transfer', 'Cash', 'Check').optional(),
        createdAt: Joi.date().optional(),
        updatedAt: Joi.date().optional(),
        invoice_id: Joi.number().optional(),
    });

    return schema.validate(formData, { abortEarly: false });
};