import React, { useState, ReactNode, useEffect } from 'react';
import Header from '../components/Header/index';
import Sidebar from '../components/Sidebar/index';
import { useLocation } from 'react-router-dom';
import { jwtDecode } from 'jwt-decode';
const DefaultLayout: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const { pathname } = useLocation();

  useEffect(() => {
    const token = localStorage.getItem('token');

    // verify token is a valid JWT token
    const isValidToken = (token: string) => {
      if (!token) {
        return false;
      }

      try {
        const jwtData = jwtDecode(token) as { exp: number };
        const currentTime = new Date().getTime() / 1000;

        if (jwtData.exp < currentTime) {
          return false;
        }
      } catch (e) {
        return false;
      }

      return true;
    }

    if (token && !isValidToken(token) && pathname !== '/login' && pathname !== '/register') {
      localStorage.removeItem('token');
      window.location.href = '/login';
    }
  }, []);

  return (
    <div className="dark:bg-boxdark-2 dark:text-bodydark">
      {/* <!-- ===== Page Wrapper Start ===== --> */}
      <div className="flex h-screen overflow-hidden">
        {/* <!-- ===== Sidebar Start ===== --> */}
        {
          pathname !== '/login' && pathname !== '/register' ? (
            <Sidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
          ) : null
        }
        {/* <!-- ===== Sidebar End ===== --> */}

        {/* <!-- ===== Content Area Start ===== --> */}
        <div className="relative flex flex-1 flex-col overflow-y-auto overflow-x-hidden">
          {/* <!-- ===== Header Start ===== --> */}
          {
            pathname !== '/login' && pathname !== '/register' ? (
                  <Header sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
              ) : null
          }
          {/* <!-- ===== Header End ===== --> */}

          {/* <!-- ===== Main Content Start ===== --> */}
          <main>
            <div className="mx-auto max-w-screen-2xl p-4 md:p-6 2xl:p-10">
              {children}
            </div>
          </main>
          {/* <!-- ===== Main Content End ===== --> */}
        </div>
        {/* <!-- ===== Content Area End ===== --> */}
      </div>
      {/* <!-- ===== Page Wrapper End ===== --> */}
    </div>
  );
};

export default DefaultLayout;
