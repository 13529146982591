import { Paper } from "@mui/material";

export default function SearchProductCard({ product, prefillProduct }) {
    return (
        <Paper key={product._id} className="p-3">
            <h3 className="text-lg font-bold">{product.name}</h3>
            <p className="text-sm">Prix: {product.price} €</p>
            <p className="text-sm">Stock: {product.stock}</p>
            <button className="bg-blue-500 text-white p-2 rounded hover:bg-blue-700" onClick={(e) => {
                e.preventDefault();
                prefillProduct(product);
            }}>Préremplir</button>
        </Paper>
    )
}