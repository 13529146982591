import React, { useState } from 'react';
import { Paper } from '@mui/material';
import { DSProduct } from '../../types';
import ModalConfirm from '../ModalConfirm';

interface OrderProductCardProps {
  product: DSProduct,
  client: any,
  productsOrdered: DSProduct[],
  setProductsOrdered: any,
  editing: boolean,
  index: number,
}

export default function OrderProductCard({ product, client, productsOrdered, setProductsOrdered, editing, index  }: OrderProductCardProps) {

  const [open, setOpen] = useState(false);
  const match = productsOrdered.find((p) => p.id === product.id);
  const p = match ? match : product;

  const modalContent = (
    <Paper sx={{
      height: '50%',
      width: '100%',
      borderRadius: '10px',
    }}>
      <label htmlFor="quantity">Modifier la quantité</label>
        <input
          type="number"
          min={1}
          max={product.maxQuantity}
          name="quantity"
          placeholder="Nom du produit"
          value={product.quantity}
          disabled={client ? false : true}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            e.preventDefault();
            setProductsOrdered(productsOrdered.map((p) => {
              if (p.id === product.id) {
                return {
                  ...p,
                  quantity: e.target.value,
                  remisedPriceTotal: p.remisedPricePerUnit * e.target.value as any, 
                }
              }
              return p;
            }))
          }}
          className="mt-1 block w-full px-3 py-2 border disabled:bg-gray-400 border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm dark:border-dark dark:text-white dark:bg-boxdark dark:border-strokedark"
        />
    </Paper>
  )

  if (editing) {
    return (
      <Paper key={index} className="p-3">
        <h3 className="text-lg font-bold">{product.name || product.product.name}</h3>
        <p className="text-sm">Quantité: {product.quantity}</p>
        <p className="text-sm">Prix unitaire: {product.priceAtOrder} €</p>
        {editing && product.rules_id !== 'no-rule' && client ? (
          <>
            <p className='text-sm'>
              Promotion: {product.rules_id !== 'no-rules' ? "Oui" : "Non"}
            </p>
            <p className='text-sm'>
              Prix remisé par unité: {product.remisedPricePerUnit} €
            </p>
            <p className='text-sm'>
              Prix remisé au total: {product.remisedPriceTotal} €
            </p>
            <p className='text-sm'>
              Prix total sans remise: {product.quantity * product.priceAtOrder} €
            </p>
            <p className='text-sm'>
              Prix total avec remise: {((product.quantity * product.priceAtOrder) - product.remisedPriceTotal).toFixed(2)} €
            </p>
          </>
        ) : null}
        {product.rules_id !== 'no-rule' && product.canApplyPromo && product.isRemised ? (
          <small className='text-green-500'>Le produit {product.name} ce verras appliquer la promotion ayant pour identifiant: {product.rules_id}</small>
        ) : null}
        {product.canApplyPromo === false && client ? (
          <small className='text-red-500'>Le produit {product.name} ne peux pas recevoir de promotion ayant pour identifiant: {product.rules_id} pour le client {client.firstname + ' ' + client.lastname} <br /> Quantité souhaité: {product.quantity}  quantité max: {product.maxQuantity}</small>
        ) : null}
        <hr className='my-3' />
        <div className='flex gap-3'>
          <button className="bg-red-500 text-white p-1 text-sm rounded hover:bg-red-700" onClick={(e) => {
            e.preventDefault();
              // TODO: Remove product from order if we are in editing mode
              setProductsOrdered(productsOrdered.filter((p) => p._id !== product._id));
            }}>Supprimer {editing ? 'de la commande' : null}</button>
          <button className="bg-indigo-500 text-white p-1 text-sm rounded hover:bg-indigo-700" onClick={(e) => {
            e.preventDefault();
            if(!open) {
              setOpen(true);
            }
          }}>
            Modifier la quantité
          </button>
        </div>
        <ModalConfirm isOpen={open} onClose={() => setOpen(false)} content={modalContent as any } title={'Modifier la quantité pour le produit: ' + (product.name || p.product.name)} cancelText='OK' />
      </Paper>
    )
  }
  return (
    <Paper key={index} className="p-3">
      <h3 className="text-lg font-bold">{product.name}</h3>
      <p className="text-sm">Quantité: {product.quantity}</p>
      <p className="text-sm">Prix unitaire: {product.priceAtOrder} €</p>
      {product.isRemised && product.canApplyPromotion ? (
        <>
          <p className='text-sm'>Promotion: {product.isRemised ? "Oui" : "Non"}</p>
          <p className='text-sm'>Promotion par unité: {product.remisedPricePerUnit} €</p>
          <p className='text-sm'>Promotion aux total: {product.remisedPriceTotal} €</p>
        </>
      ) : null}
      {product.isRemised && product.canApplyPromotion ? (
        <>
          <p className="text-sm">Prix total avec remise: {((product.priceAtOrder * product.quantity) - product.remisedPriceTotal).toFixed(2)} €</p>
          <p className="text-sm">Prix total sans remise: {(product.priceAtOrder * product.quantity).toFixed(2)} €</p>
        </>
      ) : (
        <p className="text-sm">Prix total: {((product.priceAtOrder * product.quantity)).toFixed(2)} €</p>
      )}
      {!editing && product.rules_id !== 'no-rule' && !product.canApplyPromotion ? (
        <small className='text-red-500'>Le produit {product.name} ne peux pas recevoir de promotion ayant pour identifiant: {product.rules_id} pour le client {client.firstname + ' ' + client.lastname} <br /> Quantité souhaité: {product.quantity}  quantité max: {product.maxQuantity}</small>
      ) : product.rules_id !== 'no-rule' && product.canApplyPromo ? (
        <small>Le produit {product.name} ce verras appliquer la promotion ayant pour identifiant: {product.rules_id}</small>
      ) : null}
      <hr className='my-3' />
      <div className='flex gap-3'>
        <button className="bg-red-500 text-white p-1 text-sm rounded hover:bg-red-700" onClick={(e) => {
          e.preventDefault();
          // TODO: Remove product from order if we are in editing mode
          setProductsOrdered(productsOrdered.filter((p) => p.id !== product.id));
        }}>Supprimer {editing ? 'de la commande' : null}</button>
        <button className="bg-indigo-500 text-white p-1 text-sm rounded hover:bg-indigo-700" onClick={(e) => {
            e.preventDefault();
            if(!open) {
              setOpen(true);
            }
          }}>
            Modifier la quantité
          </button>
      </div>
      <ModalConfirm isOpen={open} onClose={() => setOpen(false)} content={modalContent as any } title={'Modifier la quantité pour le produit: ' + (product.name || p.product.name)} cancelText='OK' />
    </Paper>
  )
}