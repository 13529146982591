import dayjs from "dayjs";
import { Link } from "react-router-dom";
import config from "../../config";
import { useState } from "react";
import { generateInvoicePDF, parseStatus } from "../../utils/order";

const InvoiceDetails = (order) => {
  const [emailStatus, setEmailStatus] = useState(null);

  const sendInvoiceByEmail = async () => {
    try {
      setEmailStatus('Facture envoyée avec succès.');
    } catch (error) {
      console.error("Erreur lors de l'envoi de la facture par email:", error);
      setEmailStatus("Erreur lors de l'envoi de la facture.");
    }
  };
  const allProductTotalPriceWithoutRemisedPrice = order?.productsOrdered?.map((item) => item.priceAtOrder * item.quantity).reduce((a, b) => a + b, 0) || 0;
  const allProductTotalPriceWithRemisedPrice = order?.productsOrdered?.map((item) => item.canApplyPromo ? item.remisedPriceTotal : 0).reduce((a, b) => a + b, 0) || 0;
  const remisedPriceTotal = allProductTotalPriceWithoutRemisedPrice - allProductTotalPriceWithRemisedPrice;
  const invoiceNumber = `${dayjs(order.createdAt).format('YYMMDD')}.${config.shop_id || '000'}-${order.invoice_id || '1'}`;

  const downloadInvoice = () => {
    return generateInvoicePDF(order);
  };

  return (
    <>
      <div className="max-w-1/4 mx-auto bg-white shadow-lg p-8 rounded-lg">
        <h2 className="text-2xl font-bold mb-6">Facture n° {invoiceNumber}</h2>

        <div className="mb-8">
          <h3 className="text-lg font-semibold mb-2">Informations du Client</h3>
          <p><span className="font-medium">Nom:</span> {order?.customer?.customer_full_name}</p>
          <p><span className="font-medium">Email:</span> {order?.customer?.customer_email}</p>
          <p><span className="font-medium">Téléphone:</span> {order?.customer?.customer_phone}</p>
          <p>
            <span className="font-medium">Adresse:</span> {order?.customer?.customer_address}, {order?.customer?.customer_city}, {order?.customer?.customer_postalCode}, {order?.customer?.customer_country}
          </p>
        </div>

        <div className="mb-8">
          <h3 className="text-lg font-semibold mb-4">Détails de la Commande</h3>
          <table className="w-full border border-gray-300">
            <thead>
              <tr className="bg-gray-100">
                <th className="py-2 px-4 border-b font-medium text-left">Produit</th>
                <th className="py-2 px-4 border-b font-medium text-left">Prix Unitaire</th>
                <th className="py-2 px-4 border-b font-medium text-left">Quantité</th>
                <th className="py-2 px-4 border-b font-medium text-left">Réduction</th>
                <th className="py-2 px-4 border-b font-medium text-left">Promo applicable ?</th>
              </tr>
            </thead>
            <tbody>
              {order?.productsOrdered?.map((item, index) => {
                const unitPrice = item.isRemised ? item.remisedPricePerUnit : item.priceAtOrder;
                const lineTotal = (unitPrice * item.quantity).toFixed(2);

                return (
                  <tr key={index} className="border-b">
                    <td className="py-2 px-4">{item.product.name}</td>
                    <td className="py-2 px-4">{item.priceAtOrder.toFixed(2)} €</td>
                    <td className="py-2 px-4">{item.quantity}</td>
                    <td className="py-2 px-4">{lineTotal} €</td>
                    <td className="py-2 px-4">{item.canApplyPromo ? "Oui" : "Non"}</td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>

        <div className="flex justify-between">
          <div></div>
          <div className="mb-8">
            <h3 className="text-lg font-semibold mb-4">Résumé</h3>
            <p><span className="font-medium">Total sans reduction:</span> {allProductTotalPriceWithoutRemisedPrice.toFixed(2)} €</p>
            <p><span className="font-medium">Total avec reduction:</span> {allProductTotalPriceWithRemisedPrice.toFixed(2)} €</p>
            <p><span className="font-medium">Total reduction:</span> {remisedPriceTotal.toFixed(2)} €</p>

            <p><span className="font-medium">Status:</span> {parseStatus(order?.status)}</p>
            <p><span className="font-medium">Statut de paiement:</span> {parseStatus(order?.paymentStatus)}</p>
            <p><span className="font-medium">Méthode de paiement:</span> {parseStatus(order?.paymentMethod)}</p>
          </div>
        </div>
      </div>
      <div className="max-w-3xl mx-auto bg-white shadow-lg p-8 rounded-lg mt-3 print:hidden">
        <div>
          <h2 className="text-2xl font-bold mb-6">Action disponible sur cette facture</h2>
        </div>
        <div className="flex justify-between">
          <button className={'p-2 bg-indigo-500 text-white rounded hover:bg-indigo-700 hover:shadow-lg'} onClick={downloadInvoice} >
            Télécharger la facture.
          </button>
          <button className={'p-2 bg-green-500 text-white rounded hover:bg-green-700 hover:shadow-lg'} onClick={() => {
            window.print();
          }}>
            Imprimer la facture.
          </button>
          <button className={'p-2 bg-blue-500 text-white rounded hover:bg-blue-700 hover:shadow-lg'}>
            Envoyer la facture par email.
          </button>
          <Link to={'/order-details/' + order._id}>
            <button className={'p-2 bg-pink-500 text-white rounded hover:bg-pink-700 hover:shadow-lg'}>
              Voir la commande.
            </button>
          </Link>
        </div>
      </div>
    </>
  );
};

export default InvoiceDetails;
