import { fetchOrders } from "../utils/order";
import Loader from "../components/Loader";
import InvoicesCreate from "./InvoicesCreate";

export default function OrderDetailsLoader() {
    const { data, error, loading } = fetchOrders();

    if (loading) {
        return <Loader message="Chargement des commande..." />;
    }

    if (error) {
        return <div>Une erreur est survenue</div>;
    }

    return <InvoicesCreate {...data} />;
}