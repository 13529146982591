import { fetchOrder } from "../utils/order";
import { useParams } from "react-router-dom";
import Loader from "../components/Loader";
import OrderDetails from "./OrderDetails";

export default function OrderDetailsLoader() {
    const { id } = useParams();
    const { data, error, loading } = fetchOrder(id);

    if (loading) {
        return <Loader message="Chargement de la commande..." />;
    }

    if (error) {
        return <div>Une erreur est survenue</div>;
    }

    return <OrderDetails {...data} />;
}