import React from 'react';
import Breadcrumb from '../components/Breadcrumbs/Breadcrumb';
import { parseStatus } from '../utils/order';
import dayjs from 'dayjs';
import { Link } from 'react-router-dom';
import DSProduct from '../types/ds_product';
import OrderDetailsProps from '../types/order_details_props';

const OrderDetails: React.FC<OrderDetailsProps> = ({
  customer,
  invoice,
  totalAmount,
  status,
  paymentStatus,
  paymentMethod,
  productsOrdered,
  createdAt,
  updatedAt,
  _id,
}) => {

  const totalAmountWithReduction = productsOrdered.reduce((acc, product) => {
    let res = 0;
    if (product.canApplyPromo) {
      let promo = parseFloat(String(product.remisedPriceTotal));
      const result = parseFloat(String(product.quantity * product.priceAtOrder));
      res += result - promo;
    } else {
      res += product.priceAtOrder * product.quantity;
    }
    return acc + res;
}, 0);


  return (
    <div className="container mx-auto px-4 py-6">
      <Breadcrumb pageName="Détails de la commande" />
      <div>
        <Link to={`/order-create?id=${_id}&editing=true`}>
          <button className="bg-blue-500 p-2 rounded text-white shadow hover:shadow-lg hover:bg-blue-700">
            Modifier la commande
          </button>
        </Link>
      </div>
      <div className="mt-4">
        <p className="text-sm text-gray-500">
          Commande passée le{' '}
          {dayjs(createdAt).format('dddd DD MMMM YYYY à hh:mm')}
        </p>
      </div>
      <div className="bg-white shadow-md rounded-lg p-6 mt-4">
        <h2 className="text-2xl font-bold mb-4">Informations sur le client</h2>
        <div className="space-y-4 mb-4">
          <p>
            <strong>Nom : </strong>
            {customer.customer_full_name}
          </p>
          <p>
            <strong>Email : </strong>
            {customer.customer_email}
          </p>
          <p>
            <strong>Téléphone : </strong>
            {customer.customer_phone}
          </p>
          <p>
            <strong>Adresse : </strong>
            {customer.customer_address}, {customer.customer_city},{' '}
            {customer.customer_postalCode}, {customer.customer_country}
          </p>
        </div>

        <h2 className="text-2xl font-bold mb-4">Informations de facturation</h2>
        <div className="space-y-4 mb-4">
          <p>
            <strong>Nom : </strong>
            {invoice.invoice_full_name}
          </p>
          <p>
            <strong>Email : </strong>
            {invoice.invoice_email}
          </p>
          <p>
            <strong>Téléphone : </strong>
            {invoice.invoice_phone}
          </p>
          <p>
            <strong>Adresse : </strong>
            {invoice.invoice_address}, {invoice.invoice_city},{' '}
            {invoice.invoice_postalCode}, {invoice.invoice_country}
          </p>
        </div>

        <h2 className="text-2xl font-bold mb-4">Statut de la commande</h2>
        <div className="space-y-4 mb-4">
          <p>
            <strong>Statut : </strong>
            {parseStatus(status)}
          </p>
          <p>
            <strong>Statut de paiement : </strong>
            {parseStatus(paymentStatus)}
          </p>
        </div>

        <h2 className="text-2xl font-bold mb-4">Paiement</h2>
        <div className="space-y-4 mb-4">
          <p>
            <strong>Méthode de paiement : </strong>
            {parseStatus(paymentMethod)}
          </p>
        </div>

        <h2 className="text-2xl font-bold mb-4">Produits commandés</h2>
        <div className="mb-4">
          {productsOrdered.map((product: DSProduct) => (
            <div
              key={product._id}
              className="space-y-4 mb-4 p-4 border rounded-lg"
            >
              <p>
                <strong>Produit : </strong>
                {product.product.name}
              </p>
              <p>
                <strong>Référence : </strong>
                {product.product.references}
              </p>
              <p>
                <strong>Description : </strong>
                {product.product.description.substring(0, 100)}...
              </p>
              <p>
                <strong>Prix unitaire : </strong>
                {product.priceAtOrder.toFixed(2)} €
              </p>
              <p>
                <strong>Quantité : </strong>
                {product.quantity}
              </p>
              <p>
                <strong>Promo : </strong>
                {product.canApplyPromo ? "Promotion applicable lors de la création de la commande" : "Pas de promotion applicable lors de la création de la commande"}
              </p>
              <p>
                <strong>Valide du : </strong>
                {new Date(
                  product.product.validity.startDate,
                ).toLocaleDateString()}{' '}
                au{' '}
                {new Date(
                  product.product.validity.endDate,
                ).toLocaleDateString()}
              </p>
            </div>
          ))}
        </div>

        <h2 className="text-2xl font-bold mb-4">Montant total (sans promotion)</h2>
        <p>
          <strong>Total : </strong>
          {totalAmount.toFixed(2)} €
        </p>

        <h2 className="text-2xl font-bold mb-4">Montant total (avec promotion si applicable)</h2>
        <p>
          <strong>Total : </strong>
          {totalAmountWithReduction.toFixed(2)} €
        </p>

        <p className="mt-4 text-sm text-gray-500">
          Dernière mise à jour :{' '}
          {dayjs(updatedAt).format('dddd DD MMMM YYYY à hh:mm')}
        </p>
      </div>
    </div>
  );
};

export default OrderDetails;
