import { fetchOrder } from "../utils/order";
import Loader from "../components/Loader";
import { useParams } from "react-router-dom";
import InvoiceDetails from "../components/Invoices/Details";

export default function InvoiceViewLoader() {
    const { id } = useParams();
    const { data, error, loading } = fetchOrder(id);

    if (loading) {
        return <Loader message="Chargement de la..." />;
    }

    if (error) {
        return <div>Une erreur est survenue</div>;
    }

    return <InvoiceDetails {...data} />;
}