import React, { useEffect, useCallback } from "react";
import { motion } from "framer-motion";

interface ModalConfirmProps {
    isOpen: boolean,
    onClose: () => void,
// @ts-ignore
    title: string,
    message?: string,
    cancelText?: string,
// @ts-ignore
    content: string,
}

const ModalConfirm: React.FC<ModalConfirmProps> = ({
                                                       isOpen,
                                                       onClose,
// @ts-ignore
                                                       title,
                                                       message = "",
                                                       cancelText = "Non",
// @ts-ignore
                                                       content = "",
                                                   }) => {
    const variants = {
        hidden: { opacity: 0, scale: 0.8 },
        visible: {
            opacity: 1,
            scale: 1,
            transition: { type: "spring", stiffness: 300, damping: 20 },
        },
        exit: {
            opacity: 0,
            scale: 0.8,
            transition: { type: "spring", stiffness: 300, damping: 20 },
        },
    };

    const handleKeyDown = useCallback(
        (event: KeyboardEvent) => {
            if (event.key === "Escape") {
                onClose();
            }
        },
        [onClose]
    );

    const handleClickOutside = useCallback(
        (event) => {
            const modal = document.getElementById("modal");
            if (modal && !modal.contains(event.target)) {
                onClose();
            }
        },
        [onClose]
    );

    useEffect(() => {
        if (isOpen) {
            document.body.style.overflow = "hidden";
            window.addEventListener("keydown", handleKeyDown);
            document.addEventListener("mousedown", handleClickOutside);
        } else {
            document.body.style.overflow = "auto";
        }

        return () => {
            document.body.style.overflow = "auto";
            window.removeEventListener("keydown", handleKeyDown);
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [isOpen, handleClickOutside, handleKeyDown]);

    if (!isOpen) return null;

    return (
        <div className="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-50">
            <motion.div
                id="modal"
                className="bg-white p-8 rounded shadow-lg w-full max-w-2xl"
                initial="hidden"
                animate="visible"
                exit="exit"
                variants={variants}
            >
                <div className="newsletter-preview bg-text p-4 rounded">
                    <h2 className="text-xl mb-4 text-textBlack">
                        {title}
                    </h2>
                    <div
                        className="newsletter-content max-h-96 overflow-y-auto text-textHalfBlack">
                        {content}
                    </div>
                </div>
                <>
                    <p className="text-sm mb-4">{message}</p>
                    <div className="flex justify-center gap-4">
                        <button
                            className="px-4 py-2 bg-common rounded hover:bg-commonHover transition duration-300"
                            onClick={onClose}
                        >
                            {cancelText}
                        </button>
                    </div>
                </>
            </motion.div>
        </div>
    );
};

export default ModalConfirm;
