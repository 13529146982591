export const DEFAULT_CUSTOMER_VALUE = {
    customer_full_name: '',
    customer_email: '',
    customer_phone: '',
    customer_address: '',
    customer_city: '',
    customer_postalCode: '',
    customer_country: '',
    ps_customer_id: '',
}

export const DEFAULT_INVOICE_VALUE = {
    invoice_full_name: '',
    invoice_email: '',
    invoice_phone: '',
    invoice_address: '',
    invoice_city: '',
    invoice_postalCode: '',
    invoice_country: ''
}

export const DEFAULT_NEW_PRODUCT_VALUE = {
    name: '',
    id: '',
    quantity: 0,
    priceAtOrder: 0,
    remisedPricePerUnit: 0,
    remisedPriceTotal: 0,
    isRemised: false,
    maxQuantity: 0,
    rules_id: ''
}